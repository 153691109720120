import React from "react";
import { AREAS } from "./constants";
import "./TwoColumnAreaFilter.css";

function TwoColumnAreaFilter({ currArea, setArea }) {
  // Sort the areas alphabetically by name, excluding "All"
  const sortedAreas = AREAS.filter((area) => area.name !== "All").sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <aside className="two-column-area-filter">
      <h2>choose area</h2>
      <ul className="area-grid">
        {sortedAreas.map((area) => (
          <li key={area.name}>
            <button
              className={`area-button ${
                currArea === area.name ? "active" : ""
              }`}
              onClick={() => setArea(area.name)}
              style={{ backgroundColor: area.color }}
            >
              {area.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default TwoColumnAreaFilter;
