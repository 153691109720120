import React, { useState, useEffect } from "react";
import Card from "./Card";
import Rec from "./Rec";
import "./Deck.css";
import CardHeader from "./CardHeader";
import mixpanel from "mixpanel-browser";
import { handleSortByDistance, handleSortByUpvotes } from "./utils";

function Deck({ recs, setRecs, saveRec, openFilterModal }) {
  const [rightSwipedRecs, setRightSwipedRecs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [displayedRecs, setDisplayedRecs] = useState([]);

  useEffect(() => {
    if (recs && recs.length > 0) {
      setDisplayedRecs(recs.slice(0, 10));
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, [recs]);

  const handleSwipeRight = (rec) => {
    setRightSwipedRecs((prevRecs) => [...prevRecs, rec]);
    setDisplayedRecs((prevRecs) => prevRecs.filter((r) => r.id !== rec.id));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (displayedRecs.length === 0) {
    mixpanel.track("Card View", {
      swiped_all: "yes",
    });
  }

  if (
    (!displayedRecs || displayedRecs.length === 0) &&
    rightSwipedRecs.length === 0
  ) {
    return (
      <div>
        <CardHeader
          onSortByDistance={() => {
            return new Promise((resolve) => {
              handleSortByDistance(recs).then((sortedRecs) => {
                setRecs(sortedRecs);
                resolve();
              });
            });
          }}
          onSortByUpvotes={() => handleSortByUpvotes(recs, setRecs)}
        />
        <div className="no-recs-container">
          <p>man.. no recs found</p>
          <button className="search-again-button" onClick={openFilterModal}>
            wanna try again?
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <CardHeader
        onSortByDistance={() => {
          return new Promise((resolve) => {
            handleSortByDistance(recs).then((sortedRecs) => {
              setRecs(sortedRecs);
              resolve();
            });
          });
        }}
        onSortByUpvotes={() => handleSortByUpvotes(recs, setRecs)}
      />
      <div className="deck-container">
        {displayedRecs && displayedRecs.length > 0 && (
          <div className="deck">
            {displayedRecs.map((rec, index) => (
              <Card
                key={rec.id}
                rec={rec}
                setRecs={setDisplayedRecs}
                index={index}
                saveRec={saveRec}
                isTopRecommendation={index < 2}
                handleSwipeRight={handleSwipeRight}
              />
            ))}
          </div>
        )}
        <div className="right-swiped-container">
          <ul className="right-swiped-list">
            {rightSwipedRecs.map((rec, index) => (
              <Rec
                key={rec.id}
                rec={rec}
                setRecs={setRightSwipedRecs}
                index={index}
                saveRec={saveRec}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Deck;
