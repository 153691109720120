// AuthContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { supabase } from "./supabase";
import mixpanel from "mixpanel-browser";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const userCheckedRef = useRef(false);

  useEffect(() => {
    async function handleUserSession(session) {
      if (session?.user && !userCheckedRef.current) {
        await ensureUserInDatabase(session.user);
        setUser(session.user);
        userCheckedRef.current = true;
      } else if (!session?.user) {
        setUser(null);
        userCheckedRef.current = false;
      }
    }

    async function setupAuth() {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) {
        console.error("Error fetching session:", error);
      } else {
        await handleUserSession(session);
      }

      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          console.log("Auth state changed:", event, session);
          mixpanel.identify(session.user.id);
          mixpanel.people.set({
            $email: session.user.email,
          });

          if (event === "SIGNED_IN") {
            await handleUserSession(session);
          } else if (event === "SIGNED_OUT") {
            setUser(null);
            userCheckedRef.current = false;
          }
        }
      );

      return () => {
        authListener.subscription.unsubscribe();
      };
    }

    setupAuth();
  }, []);

  async function ensureUserInDatabase(user) {
    if (!user || !user.id) {
      console.error("Invalid user object:", user);
      return;
    }

    try {
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("id", user.id);

      if (error) {
        console.error("Error checking user existence:", error);
        return;
      }

      if (data.length === 0) {
        const { data: insertData, error: insertError } = await supabase
          .from("users")
          .upsert({ id: user.id, email: user.email })
          .select();

        if (insertError) {
          console.error("Error creating user record:", insertError);
        } else {
          console.log(
            "User record created or updated successfully:",
            insertData
          );
        }
      } else {
        console.log("User already exists in database");
      }
    } catch (error) {
      console.error("Unexpected error in ensureUserInDatabase:", error);
    }
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
