// AppContext.js
import React, { createContext, useState, useEffect } from "react";
import { supabase } from "./supabase"; // Ensure you have your supabase import here

export const AppContext = createContext();

const getInitialFilters = () => {
  const filters = localStorage.getItem("filters");
  return filters
    ? JSON.parse(filters)
    : {
        selectedCategories: [],
        selectedLocation: "",
        selectedRating: "",
        selectedPrice: "",
        selectedTags: [], // Ensure selectedTags is part of filters
        selectedArea: "All",
        selectedButton: "upvotes",
        distanceFilter: 5,
        priceRange: [1, 4], // Add this line
      };
};

const getInitialCategory = () => {
  const category = localStorage.getItem("currCategory");
  return category ? JSON.parse(category) : "All";
};

const getInitialLocation = () => {
  const location = localStorage.getItem("location");
  return location ? JSON.parse(location) : "";
};

const getInitialShowAsCards = () => {
  const showAsCards = localStorage.getItem("showAsCards");
  return showAsCards ? JSON.parse(showAsCards) : false;
};

export const AppProvider = ({ children }) => {
  const [currCategory, setCurrCategory] = useState(getInitialCategory());
  const [location, setLocation] = useState(getInitialLocation());
  const [showAsCards, setShowAsCards] = useState(getInitialShowAsCards());
  const [filters, setFilters] = useState(getInitialFilters());
  const [isLoading, setIsLoading] = useState(false);
  const [recs, setRecs] = useState([]);
  const [originalRecs, setOriginalRecs] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [isSorting, setIsSorting] = useState(false);
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    localStorage.setItem("currCategory", JSON.stringify(currCategory));
  }, [currCategory]);

  useEffect(() => {
    localStorage.setItem("location", JSON.stringify(location));
  }, [location]);

  useEffect(() => {
    localStorage.setItem("showAsCards", JSON.stringify(showAsCards));
  }, [showAsCards]);

  const toggleTag = (tagName) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedTags: prevFilters.selectedTags.includes(tagName)
        ? prevFilters.selectedTags.filter((tag) => tag !== tagName)
        : [...prevFilters.selectedTags, tagName],
    }));
  };

  const resetFilters = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      selectedRating: "",
      selectedPrice: "",
      selectedTags: [],
      selectedArea: "All",
      priceRange: [1, 4],
    }));
  };

  const contextValue = {
    currCategory,
    setCurrCategory,
    location,
    setLocation,
    showAsCards,
    setShowAsCards,
    filters,
    setFilters,
    isLoading,
    setIsLoading,
    recs,
    setRecs,
    originalRecs,
    setOriginalRecs,
    userLocation,
    setUserLocation,
    isSorting,
    setIsSorting,
    toggleTag,
    resetFilters,
    showOnboardingModal,
    setShowOnboardingModal,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
