import React, { useState } from "react";
import "./NewRecTip.css";

const NewRecTip = ({ setTip }) => {
  const [tipText, setTipText] = useState("");

  const handleTipChange = (e) => {
    setTipText(e.target.value);
    setTip(e.target.value);
  };

  return (
    <div className="tip-container">
      <h2 className="tip-heading">share the first insider tip!</h2>
      <h4>
        what makes this place <span className="highlight">special</span>?
      </h4>
      <div className="tip-input-wrapper">
        <input
          className="tip-input"
          placeholder="70 characters only"
          value={tipText}
          onChange={handleTipChange}
          maxLength={100}
          required
        />
        <div className="tip-character-count">
          <span className={tipText.length >= 50 ? "near-limit" : ""}>
            {tipText.length}
          </span>
          <span className="max-chars">/70</span>
        </div>
      </div>
    </div>
  );
};

export default NewRecTip;
