// ResultsPage.js
import React from "react";
import CategoryFilter from "./CategoryFilter";

function ListsPage() {
  return (
    <div>
      <h2
        className="headline"
        style={{ marginTop: -10, marginBottom: 10, textAlign: "center" }}
      >
        public recs that hit the sweet spot 🎯
      </h2>
      <h2 style={{ textAlign: "center", textTransform: "lowercase" }}>
        Best of NY recs
      </h2>
      <CategoryFilter />
    </div>
  );
}

export default ListsPage;
