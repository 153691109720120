// CategoryFilter.js
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CATEGORIES } from "./constants";
import { AppContext } from "./AppContext";
import "./CategoryFilter.css"; // Ensure you import the CSS file

function CategoryFilter() {
  const {
    currCategory,
    setCurrCategory,
    showAsCards,
    setShowAsCards,
  } = useContext(AppContext);

  return (
    <aside>
      <ul className="category-list">
        {CATEGORIES.map((category) => (
          <li key={category.name}>
            <Link to={`/category`}>
              <button
                className={`category-button ${
                  currCategory === category.name ? "active" : ""
                }`}
                onClick={() => {
                  setCurrCategory(category.name);
                  setShowAsCards(false);
                }}
              >
                {category.name}
              </button>
            </Link>
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default CategoryFilter;
