import React, { useState } from "react";
import { supabase } from "./supabase";
import AutoComplete from "./autocomplete";
import { CATEGORIES, AREAS } from "./constants";
import { useAuth } from "./AuthContext";
import { getYelpBusinessDetails, getBusinessById } from "./yelpService";
import {
  FaTimes,
  FaMapMarkerAlt,
  FaTag,
  FaBuilding,
  FaChevronLeft,
} from "react-icons/fa";
import "./NewRecModal.css";
import TwoColumnAreaFilter from "./TwoColumnAreaFilter";
import NewRecCategories from "./NewRecCategories";
import NewRecTip from "./NewRecTip";
import NewRecTags from "./NewRecTags";

function NewRecForm({ setRecs, setShowForm }) {
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [rating, setRating] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [openingHours, setOpeningHours] = useState(null);
  const [priceLevel, setPriceLevel] = useState(null);
  const [types, setTypes] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [userRatingsTotal, setUserRatingsTotal] = useState(null);
  const [placeId, setPlaceId] = useState("");
  const [placeSelected, setPlaceSelected] = useState(false);
  const [step, setStep] = useState(1);
  const [tip, setTip] = useState("");
  const [newRecId, setNewRecId] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [photos, setPhotos] = useState([]); // Add this line

  const resetForm = () => {
    setName("");
    setAddress("");
    setLatitude("");
    setLongitude("");
    setRating(null);
    setReviews([]);
    setOpeningHours(null);
    setPhoneNumber("");
    setWebsite("");
    setUserRatingsTotal(null);
    setPlaceId("");
    setPriceLevel(null);
    setTypes([]);
    setPlaceSelected(false);
    setStep(1);
  };

  const handleBack = () => {
    if (step === 2) {
      resetForm();
    } else if (step === 3) {
      setStep(2);
      setCategory("");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (isUploading) return;

    setIsUploading(true);

    try {
      console.log("Current user data:", user);

      if (step < 4) {
        if (!address) {
          alert(
            "Please enter a name using the autocomplete feature in order for us to capture the address."
          );
          return;
        }

        if (!area || !category) {
          alert("Please select an area and a category.");
          return;
        }

        if (name && area && category && address) {
          setIsUploading(true);

          // Use existing Google Places API photo URLs
          let photoUrls = null;
          if (photos && photos.length > 0) {
            photoUrls = photos.join(", ");
          }

          // Call Yelp API to get category data and potentially photo URLs
          let categories = null;
          let yelpPrice = null;
          let yelpPhone = null;
          let yelpWebsite = null;

          try {
            const response = await getYelpBusinessDetails(name, address);
            console.log(response);
            if (
              response &&
              response.data &&
              response.data.businesses &&
              response.data.businesses.length > 0
            ) {
              const businessId = response.data.businesses[0].id;
              const detailsResponse = await getBusinessById(businessId);
              if (
                detailsResponse &&
                detailsResponse.data &&
                detailsResponse.data.categories
              ) {
                categories = detailsResponse.data.categories
                  .map((cat) => cat.title)
                  .join(", ");

                // Only use Yelp photos if Google Places API didn't provide any
                if (!photoUrls && detailsResponse.data.photos) {
                  photoUrls = detailsResponse.data.photos.join(", ");
                }

                // Use Yelp data if Google data is not available
                yelpPrice = detailsResponse.data.price
                  ? detailsResponse.data.price.length
                  : null;
                console.log(yelpPrice);
                yelpPhone = detailsResponse.data.phone;
                yelpWebsite = detailsResponse.data.url;
                console.log(detailsResponse);
              }
            }
          } catch (error) {
            console.error("Error fetching data from Yelp API:", error.message);
          }

          if (!photoUrls) {
            console.log(
              "No photos available from either Google Places or Yelp API"
            );
          }

          // Use Yelp data if Google data is not available
          const finalPriceLevel =
            priceLevel !== null && priceLevel !== undefined
              ? priceLevel
              : yelpPrice;
          const finalPhoneNumber =
            phoneNumber != null ? phoneNumber : yelpPhone;
          const finalWebsite = website != null ? website : yelpWebsite;

          // check if the place already exists
          const { data: existingRecs, error: existingError } = await supabase
            .from("recs_votes")
            .select("*")
            .eq("name", name)
            .eq("address", address);

          if (existingError) {
            console.error(
              "Error checking existing recs:",
              existingError.message
            );
            setIsUploading(false);
            return;
          }

          let newRecId;

          if (existingRecs.length > 0) {
            // add an upvote to the existing rec
            const { error: updateError } = await supabase
              .from("recs_votes")
              .update({ upvotes: existingRecs[0].upvotes + 1 })
              .eq("id", existingRecs[0].id);

            if (updateError) {
              console.error("Error updating rec:", updateError.message);
            }
            newRecId = existingRecs[0].id;
          } else {
            const { data: newRec, error: insertError } = await supabase
              .from("recs_votes")
              .insert([
                {
                  name,
                  category,
                  area,
                  address,
                  upvotes: 1,
                  user_id: user.id, // Make sure this is correct
                  latitude,
                  longitude,
                  rating,
                  reviews: JSON.stringify(reviews),
                  price_level: finalPriceLevel,
                  types: JSON.stringify(types),
                  opening_hours: JSON.stringify(openingHours),
                  phone_number: finalPhoneNumber,
                  website: finalWebsite,
                  user_ratings_total: userRatingsTotal,
                  place_id: placeId,
                  categories,
                  photo_urls: photoUrls,
                },
              ])
              .select();

            if (insertError) {
              console.error("Error creating rec:", insertError);
              alert("There was an error creating your rec");
              setIsUploading(false);
              return;
            }

            console.log("New rec created successfully:", newRec);
            setRecs((recs) => [newRec[0], ...recs]);
            newRecId = newRec[0].id;
          }

          setIsUploading(false);
          setNewRecId(newRecId);
          setStep(4); // Move to the tip input step
        }
      } else if (step === 4) {
        // Handle tip submission
        await handleSubmitTip();
        setStep(5); // Move to the tags step after submitting the tip
      } else {
        // Handle final submission with tags
        await handleFinalSubmit();
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      alert("An unexpected error occurred. Please try again.");
    } finally {
      setIsUploading(false);
    }
  }

  async function handleSubmitTip() {
    if (!tip) {
      alert("Please enter a tip before submitting.");
      return;
    }

    setIsUploading(true);

    // First, fetch the current tips
    const { data, error: fetchError } = await supabase
      .from("recs_votes")
      .select("tips")
      .eq("id", newRecId)
      .single();

    if (fetchError) {
      alert("There was an error fetching the current tips");
      console.error("Error fetching tips:", fetchError.message);
      setIsUploading(false);
      return;
    }

    // Prepare the new tip object
    const newTip = {
      text: tip,
      user_id: user.id,
      created_at: new Date().toISOString(),
    };

    // Append the new tip to the existing tips array
    const updatedTips = data.tips ? [...data.tips, newTip] : [newTip];

    // Update the tips column with the new array
    const { error: updateError } = await supabase
      .from("recs_votes")
      .update({ tips: updatedTips })
      .eq("id", newRecId);

    if (updateError) {
      alert("There was an error submitting your tip");
      console.error("Error submitting tip:", updateError.message);
    }
    setIsUploading(false);
  }

  async function handleFinalSubmit() {
    setIsUploading(true);

    // Fetch the current rec to get existing tag counts
    const { data: currentRec, error: fetchError } = await supabase
      .from("recs_votes")
      .select("tag_counts")
      .eq("id", newRecId)
      .single();

    if (fetchError) {
      alert("There was an error fetching the current rec");
      console.error("Error fetching rec:", fetchError.message);
      setIsUploading(false);
      return;
    }

    // Initialize or update tag counts
    const updatedTagCounts = { ...(currentRec.tag_counts || {}) };
    for (const tag of selectedTags) {
      updatedTagCounts[tag] = (updatedTagCounts[tag] || 0) + 1;
    }

    // Update the rec with the updated tag counts
    const { data, error: updateError } = await supabase
      .from("recs_votes")
      .update({ tag_counts: updatedTagCounts })
      .eq("id", newRecId)
      .select();

    if (updateError) {
      alert("There was an error updating your rec with tags");
      console.error("Error updating rec with tags:", updateError.message);
      setIsUploading(false);
      return;
    }

    // Update the local state with the new data
    setRecs((prevRecs) =>
      prevRecs.map((rec) =>
        rec.id === newRecId ? { ...rec, tag_counts: updatedTagCounts } : rec
      )
    );

    // Reset form fields and close the modal
    resetForm();
    setShowForm(false);
    setIsUploading(false);
  }

  return (
    <div className="new-rec-modal-backdrop" onClick={() => setShowForm(false)}>
      <div
        className="new-rec-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="new-rec-modal-header">
          {step > 1 && step <= 3 && (
            <button className="new-rec-back-btn" onClick={handleBack}>
              <FaChevronLeft />
              <span>Back</span>
            </button>
          )}
          <button
            className="new-rec-close-btn"
            onClick={() => setShowForm(false)}
          >
            <FaTimes />
          </button>
        </div>
        <form className="new-rec-form" onSubmit={(e) => e.preventDefault()}>
          {step === 1 && (
            <div>
              <AutoComplete
                setName={setName}
                setAddress={setAddress}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
                setResults={(result) => {
                  if (result.length > 0) {
                    setRating(result[0].rating);
                    setReviews(result[0].reviews);
                    setOpeningHours(result[0].opening_hours);
                    setPhoneNumber(result[0].phone_number);
                    setWebsite(result[0].website);
                    setUserRatingsTotal(result[0].user_ratings_total);
                    setPlaceId(result[0].place_id);
                    setPriceLevel(result[0].price_level);
                    setTypes(result[0].types);
                    setPlaceSelected(true);
                    setStep(2);
                  }
                }}
                placeholder="Search for a place"
              />
            </div>
          )}
          {step === 2 && (
            <div className="form-group">
              <TwoColumnAreaFilter
                currArea={area}
                setArea={(selectedArea) => {
                  setArea(selectedArea);
                  setStep(3);
                }}
              />
            </div>
          )}
          {step === 3 && (
            <div className="form-group">
              <NewRecCategories
                currCategory={category}
                setCurrCategory={setCategory}
              />
            </div>
          )}
          {step === 4 && (
            <div className="form-group">
              <NewRecTip setTip={setTip} />
            </div>
          )}
          {step === 5 && (
            <div className="form-group">
              <NewRecTags
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
              />
            </div>
          )}
          {step === 3 && (
            <button
              className="submit-button"
              disabled={isUploading || !name || !area || !category}
              onClick={handleSubmit}
            >
              {isUploading ? "Posting..." : "Share Recommendation"}
            </button>
          )}
          {step === 4 && (
            <button
              className="submit-button"
              onClick={handleSubmit}
              disabled={isUploading}
              style={{ marginTop: "-10px" }}
            >
              {isUploading ? "Submitting..." : "Submit Tip"}
            </button>
          )}
          {step === 5 && (
            <button
              className="submit-button"
              onClick={handleSubmit}
              disabled={isUploading}
              style={{ marginTop: "-10px" }}
            >
              {isUploading ? "Finalizing..." : "Finish"}
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

export default NewRecForm;
