import React, { useState } from "react";
import Modal from "react-modal";
import Slider from "react-slick";
import "./RecModal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CATEGORIES } from "./constants";
import {
  FaStar,
  FaDollarSign,
  FaPhone,
  FaGlobe,
  FaClock,
  FaCircle,
  FaHeart,
  FaShare,
  FaMapMarkerAlt,
  FaChevronDown,
  FaTimes,
  FaDirections,
  FaLightbulb,
  FaUser,
  FaCalendar,
  FaPlus,
} from "react-icons/fa";
import { ReactComponent as LeftArrow } from "./assets/left-arrow.svg";
import { ReactComponent as RightArrow } from "./assets/right-arrow.svg";
import { isOpenNow } from "./dateUtils";
import VibeTags from "./VibeTags";
import { supabase } from "./supabase";
import { useAuth } from "./AuthContext";

Modal.setAppElement("#root");

const CustomPrevArrow = (props) => (
  <button
    className="slick-arrow slick-prev custom-arrow"
    onClick={props.onClick}
  >
    <LeftArrow />
  </button>
);

const CustomNextArrow = (props) => (
  <button
    className="slick-arrow slick-next custom-arrow"
    onClick={props.onClick}
  >
    <RightArrow />
  </button>
);

function adjustColor(color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}

const RecModal = ({ isOpen, onRequestClose, rec, setRecs }) => {
  const [activeTab, setActiveTab] = useState("reviews");
  const [newTip, setNewTip] = useState("");
  const [isAddingTip, setIsAddingTip] = useState(false);
  const { user } = useAuth();

  if (!rec) return null;

  const {
    name,
    address,
    photo_urls,
    rating,
    reviews,
    opening_hours,
    phone_number,
    website,
    price_level,
    types,
    user_ratings_total,
    tag_counts,
    categories,
    tips = [],
  } = rec;

  const parsedReviews = reviews ? JSON.parse(reviews) : [];
  const parsedOpeningHours = opening_hours
    ? JSON.parse(opening_hours).weekday_text || []
    : [];
  const parsedTypes = types ? JSON.parse(types) : [];
  const periods = opening_hours ? JSON.parse(opening_hours).periods || [] : [];

  const openStatus = isOpenNow(periods) ? "open now" : "closed";

  const sliderSettings = {
    dots: photo_urls && photo_urls.length > 0,
    infinite: photo_urls && photo_urls.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    prevArrow: photo_urls && photo_urls.length > 1 ? <CustomPrevArrow /> : null,
    nextArrow: photo_urls && photo_urls.length > 1 ? <CustomNextArrow /> : null,
  };

  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/recommendation/${rec.id}`;
    const shareText = `Check out ${rec.name} on Sugar`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: "Share Recommendation",
          text: shareText,
          url: shareUrl,
        });
        console.log("Shared successfully");
      } catch (error) {
        console.log("Error sharing:", error);
      }
    } else {
      // Fallback for browsers that don't support Web Share API
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert("Link copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy: ", err);
        alert(`Please copy this link manually: ${shareUrl}`);
      }
    }
  };

  const truncateAddress = (fullAddress) => {
    const commaIndex = fullAddress.indexOf(",");
    return commaIndex !== -1 ? fullAddress.slice(0, commaIndex) : fullAddress;
  };

  const handleAddressClick = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      address
    )}`;
    window.open(url, "_blank");
  };

  const category = CATEGORIES.find((cat) => cat.name === rec.category);
  const emoji = category ? category.emoji : "";
  const categoryColor = category ? category.color : "#ff5252";

  const handleAddTip = () => {
    setIsAddingTip(!isAddingTip);
  };

  const handleSubmitTip = async () => {
    if (!newTip.trim()) {
      alert("Please enter a tip before submitting.");
      return;
    }

    if (!user) {
      alert("You must be logged in to submit a tip.");
      return;
    }

    try {
      const { data: currentTips, error: fetchError } = await supabase
        .from("recs_votes")
        .select("tips")
        .eq("id", rec.id)
        .single();

      if (fetchError) throw fetchError;

      const updatedTips = [
        ...(currentTips.tips || []),
        {
          text: newTip.trim(),
          user_id: user.id,
          created_at: new Date().toISOString(),
        },
      ];

      const { error: updateError } = await supabase
        .from("recs_votes")
        .update({ tips: updatedTips })
        .eq("id", rec.id);

      if (updateError) throw updateError;

      setRecs((prevRecs) =>
        prevRecs.map((r) => (r.id === rec.id ? { ...r, tips: updatedTips } : r))
      );

      setNewTip("");
      setIsAddingTip(false);
    } catch (error) {
      console.error("Error submitting tip:", error);
      alert("There was an error submitting your tip. Please try again.");
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "reviews":
        return (
          <>
            {parsedReviews.map((review, idx) => (
              <div key={idx} className="review-card">
                <div className="review-header">
                  <img
                    src={review.profile_photo_url}
                    alt={review.author_name}
                    className="review-avatar"
                  />
                  <div className="review-meta">
                    <p className="review-author">{review.author_name}</p>
                    <p className="review-date">
                      {review.relative_time_description}
                    </p>
                  </div>
                  <div className="review-rating">
                    <FaStar className="star-icon" />
                    <span>{review.rating}</span>
                  </div>
                </div>
                <p className="review-text">{review.text}</p>
              </div>
            ))}
          </>
        );
      case "hours":
        return (
          <>
            <ul className="hours-list">
              {parsedOpeningHours.map((time, idx) => (
                <li key={idx} className="hours-item">
                  <span className="day">{time.split(": ")[0]}</span>
                  <span className="time">{time.split(": ")[1]}</span>
                </li>
              ))}
            </ul>
          </>
        );
      case "tips":
        return (
          <>
            {isAddingTip && (
              <div className="tip-input-wrapper">
                <input
                  className="tip-input"
                  placeholder="Enter your tip (max 70 characters)"
                  value={newTip}
                  onChange={(e) => setNewTip(e.target.value.slice(0, 70))}
                  maxLength={70}
                />
                <div className="tip-character-count">
                  <span className={newTip.length >= 50 ? "near-limit" : ""}>
                    {newTip.length}
                  </span>
                  <span className="max-chars">/70</span>
                </div>
                <button className="submit-tip-button" onClick={handleSubmitTip}>
                  Submit Tip
                </button>
              </div>
            )}
            <div className="tips-container">
              {tips && tips.length > 0 ? (
                tips.map((tip, idx) => (
                  <div key={idx} className="tip-card">
                    <p className="tip-text">{tip.text}</p>
                    <div className="tip-meta">
                      <span className="tip-author">
                        <FaUser className="user-icon" /> User{" "}
                        {tip.user_id.slice(0, 8)}
                      </span>
                      <span className="tip-date">
                        <FaCalendar className="calendar-icon" />{" "}
                        {new Date(tip.created_at).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-tips">
                  No insider tips yet. Be the first to share!
                </p>
              )}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="rec-modal"
      overlayClassName="rec-modal-overlay"
      ariaHideApp={false}
    >
      <div
        className="rec-modal-content"
        style={{
          borderColor: categoryColor,
          "--category-color": adjustColor(categoryColor, 25),
          "--category-color-mid": adjustColor(categoryColor, 30),
          "--category-color-light": adjustColor(categoryColor, 40),
        }}
      >
        <button className="close-button" onClick={onRequestClose}>
          <FaTimes />
        </button>
        <div className="rec-modal-header">
          <div className="rec-modal-header-main">
            <h2>{name}</h2>
          </div>
          {rec.categories && (
            <div className="rec-modal-categories-wrapper">
              <div className="rec-modal-categories">
                {rec.categories.split(", ").map((category, idx) => (
                  <span key={idx} className="rec-modal-category-tag">
                    {category}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="rec-modal-photo-slider">
          <Slider {...sliderSettings}>
            {photo_urls && photo_urls.length > 0 ? (
              photo_urls.split(", ").map((url, idx) => (
                <div key={idx} className="rec-modal-photo-container">
                  <img
                    src={url}
                    alt={`Photo ${idx + 1}`}
                    className="rec-modal-photo"
                  />
                </div>
              ))
            ) : (
              <div className="rec-modal-photo-container">
                <div className="rec-modal-no-photo">No photos available</div>
              </div>
            )}
          </Slider>
          <div className="rec-modal-info-row overlay">
            <div className="rec-modal-status">
              <FaCircle
                className={`status-icon ${
                  openStatus === "open now" ? "open" : "closed"
                }`}
              />
              {openStatus}
            </div>
            {rating && (
              <div className="rec-modal-rating">
                <FaStar className="icon" /> {rating} ({user_ratings_total})
              </div>
            )}
            {price_level !== null && (
              <div className="rec-modal-price">
                <FaDollarSign className="icon" /> {"$".repeat(price_level)}
              </div>
            )}
          </div>
        </div>

        <div className="vibe-tags-container">
          <VibeTags
            recId={rec.id}
            tagCounts={rec.tag_counts}
            setRecs={setRecs}
          />
        </div>

        <div className="rec-modal-tabs">
          {[
            { id: "reviews", icon: <FaStar /> },
            { id: "hours", icon: <FaClock /> },
            { id: "tips", icon: <FaLightbulb /> },
          ].map((tab) => (
            <button
              key={tab.id}
              className={`rec-modal-tab ${
                activeTab === tab.id ? "active" : ""
              }`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.icon}
              <span>{tab.id.charAt(0).toUpperCase() + tab.id.slice(1)}</span>
            </button>
          ))}
        </div>
        <div className="rec-modal-tab-content">{renderTabContent()}</div>

        <div className="rec-modal-header-actions">
          <div className="rec-modal-action-button" onClick={handleAddressClick}>
            <FaDirections className="action-icon" />
            <p className="action-text">Directions</p>
          </div>
          {phone_number && (
            <a
              href={`tel:${phone_number.replace(/\D/g, "")}`}
              className="rec-modal-action-button"
            >
              <FaPhone className="action-icon" />
              <p className="action-text">Call</p>
            </a>
          )}
          {website && (
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="rec-modal-action-button"
            >
              <FaGlobe className="action-icon" />
              <p className="action-text">Website</p>
            </a>
          )}
          <div className="rec-modal-action-button" onClick={handleShare}>
            <FaShare className="action-icon" />
            <p className="action-text">Share</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RecModal;
