import React, { useState, useEffect } from "react";
import { supabase } from "./supabase";
import "./NewRecTags.css";

function NewRecTags({ selectedTags, setSelectedTags }) {
  const [tags, setTags] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [newTag, setNewTag] = useState("");

  useEffect(() => {
    fetchTags();
  }, []);

  const fetchTags = async () => {
    const { data, error } = await supabase
      .from("tags")
      .select("*")
      .order("name");
    if (error) {
      console.error("Error fetching tags:", error);
    } else {
      setTags(data.map((tag) => tag.name));
    }
  };

  const toggleTag = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleAddNewTag = async () => {
    if (newTag.trim() !== "" && !tags.includes(newTag.trim())) {
      const { data, error } = await supabase
        .from("tags")
        .insert({ name: newTag.trim() });

      if (error) {
        console.error("Error adding new tag:", error);
      } else {
        setTags([...tags, newTag.trim()]);
        setSelectedTags([...selectedTags, newTag.trim()]);
        setNewTag("");
        setIsAdding(false);
      }
    }
  };

  return (
    <div className="new-rec-tags">
      {tags.map((tag) => (
        <button
          key={tag}
          className={`new-rec-tag-bubble ${
            selectedTags.includes(tag) ? "new-rec-selected" : ""
          }`}
          onClick={() => toggleTag(tag)}
        >
          {tag}
        </button>
      ))}
      {isAdding ? (
        <div className="new-tag-input-container">
          <input
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            className="new-tag-input"
            placeholder="Enter new tag"
          />
          <button onClick={handleAddNewTag} className="new-tag-submit">
            ✓
          </button>
        </div>
      ) : (
        <button
          className="new-rec-tag-bubble add-new-tag"
          onClick={() => setIsAdding(true)}
        >
          Add new tag
        </button>
      )}
    </div>
  );
}

export default NewRecTags;
