import React, { useState } from "react";
import Slider from "react-slick";
import { CATEGORIES } from "./constants";
import { supabase } from "./supabase";
import { useDrag } from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";
import { useAuth } from "./AuthContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Rec.css";
import RecModal from "./RecModal"; // Import RecModal

function Rec({ rec, setRecs, index, saveRec }) {
  const { user } = useAuth();
  const [voteUpLoading, setVoteUpLoading] = useState(false);
  const [voteDownLoading, setVoteDownLoading] = useState(false);
  const [voteLoveLoading, setVoteLoveLoading] = useState(false);
  const [props, set] = useSpring(() => ({ x: 0, opacity: 1 }));
  const [isModalOpen, setIsModalOpen] = useState(false); // Add state for modal

  const SWIPE_THRESHOLD = 100; // Threshold for swipe registration

  const handleSwipe = (direction) => {
    if (direction === "Left") {
      // Remove the rec from the list
      setRecs((prevRecs) => prevRecs.filter((r) => r.id !== rec.id));
    } else if (direction === "Right") {
      // Save the rec name to the saved list
      setRecs((prevRecs) => prevRecs.filter((r) => r.id !== rec.id));
      saveRec(rec.name);
    }
  };

  const bind = useDrag(({ down, movement: [mx], direction: [xDir] }) => {
    const trigger = Math.abs(mx) > SWIPE_THRESHOLD; // Trigger swipe if past threshold
    const dir = xDir < 0 ? -1 : 1; // Direction: -1 for left, 1 for right
    if (trigger) {
      set({
        x: dir * window.innerWidth,
        opacity: 0,
        config: { duration: 200 },
        onRest: () => handleSwipe(dir < 0 ? "Left" : "Right"),
      });
    } else {
      set({
        x: down ? mx : 0,
        opacity: down ? 1 : 1 - Math.abs(mx) / window.innerWidth,
        immediate: down,
      });
    }
  });

  // Add click handler to open modal
  const handleRecClick = () => {
    setIsModalOpen(true);
  };

  async function handleVote(voteType) {
    if (!user) {
      alert("You need to log in to vote.");
      return;
    }

    if (rec.user_id === user.id) {
      alert("You cannot vote on your own recommendation.");
      return;
    }

    if (voteType === "upvote") setVoteUpLoading(true);
    if (voteType === "downvote") setVoteDownLoading(true);
    if (voteType === "lovevote") setVoteLoveLoading(true);

    const { data: existingVote, error: existingVoteError } = await supabase
      .from("votes")
      .select("*")
      .eq("user_id", user.id)
      .eq("rec_id", rec.id)
      .eq("vote_type", voteType);

    if (existingVoteError) {
      alert("Error checking existing vote: " + existingVoteError.message);
      setVoteUpLoading(false);
      setVoteDownLoading(false);
      setVoteLoveLoading(false);
      return;
    }

    if (existingVote.length === 0) {
      const { error: insertVoteError } = await supabase
        .from("votes")
        .insert([{ user_id: user.id, rec_id: rec.id, vote_type: voteType }]);

      if (insertVoteError) {
        alert(
          "There was an error registering your vote: " + insertVoteError.message
        );
        setVoteUpLoading(false);
        setVoteDownLoading(false);
        setVoteLoveLoading(false);
        return;
      }

      const { data: updatedRec, error: updateRecError } = await supabase
        .from("recs_votes")
        .update({ [voteType + "s"]: rec[voteType + "s"] + 1 })
        .eq("id", rec.id)
        .select();

      if (updateRecError) {
        alert(
          "There was an error updating the recommendation: " +
            updateRecError.message
        );
        setVoteUpLoading(false);
        setVoteDownLoading(false);
        setVoteLoveLoading(false);
        return;
      }

      setRecs((recs) =>
        recs.map((r) => (r.id === updatedRec[0].id ? updatedRec[0] : r))
      );

      setVoteUpLoading(false);
      setVoteDownLoading(false);
      setVoteLoveLoading(false);
    } else {
      alert("You have already voted.");
      setVoteUpLoading(false);
      setVoteDownLoading(false);
      setVoteLoveLoading(false);
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true, // Ensure the height adjusts to the content
  };

  return (
    <>
      <animated.li
        className="rec"
        {...bind()}
        style={{
          ...props,
          touchAction: "none",
          borderColor: CATEGORIES.find((el) => el.name === rec.category).color,
          backgroundColor: `${
            CATEGORIES.find((el) => el.name === rec.category).color
          }20`,
        }}
        onClick={handleRecClick} // Add onClick handler
      >
        <div className="tag_and_name">
          <p
            className="rec_name"
            style={{
              color: CATEGORIES.find((el) => el.name === rec.category).color,
            }}
          >
            {index + 1}. {rec.name} | {rec.area}
          </p>
        </div>
        <p className="summary" style={{}}>
          {!rec.categories && rec.text}
        </p>
        <div className="summary" style={{}}>
          {rec.categories &&
            rec.categories.split(", ").map((category, idx) => (
              <p key={idx} className="category-tag">
                {category}
              </p>
            ))}
        </div>
        <div className="tag_and_vote_2">
          <span
            className="tag"
            style={{
              backgroundColor: CATEGORIES.find((el) => el.name === rec.category)
                .color,
            }}
          >
            {rec.category}
          </span>
          <div className="rec-buttons">
            <button
              style={{
                borderColor: "#16a34a",
                borderWidth: 2,
                backgroundColor: `${"#16a34a"}40`,
              }}
              onClick={() => handleVote("upvote")}
              disabled={voteUpLoading}
            >
              <img
                src="/arrow-up-circle.png"
                style={{
                  paddingTop: 3,
                  paddingBottom: 0,
                  marginLeft: -2,
                  width: 15,
                }}
              />{" "}
              <span>{rec.upvotes}</span>
            </button>
            <button
              onClick={() => handleVote("lovevote")}
              disabled={voteLoveLoading}
            >
              🙌🏼 {rec.lovevotes}
            </button>
            <button
              style={{
                borderColor: "#ef4444",
                borderWidth: 2,
                backgroundColor: `${"#ef4444"}40`,
              }}
              onClick={() => handleVote("downvote")}
              disabled={voteDownLoading}
            >
              <img
                src="/arrow-down-circle.png"
                style={{
                  paddingTop: 3,
                  paddingBottom: 0,
                  marginLeft: -2,
                  width: 15,
                }}
              />{" "}
              {rec.downvotes}
            </button>
          </div>
        </div>
      </animated.li>
      {isModalOpen && (
        <RecModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          rec={rec}
          setRecs={setRecs}
        />
      )}
    </>
  );
}

export default Rec;
