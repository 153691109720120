import React from "react";
import "./Tag.css";

function Tag({ label, count, handleTagClick, isSelected }) {
  const handleClick = () => {
    handleTagClick(label);
  };

  return (
    <div
      className={`tag ${isSelected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <img
        src={isSelected ? "./check-btn-black.png" : "./add-btn.png"}
        alt={isSelected ? "Remove" : "Add"}
        className="add-btn"
      />
      <div className="tag-text">
        <span className="tag-name">{label}</span>
        <span className="tag-separator"> | </span>
        <span className="tag-count">{count}</span>
      </div>
    </div>
  );
}

export default Tag;
