// Home.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "./supabase";
import FilterPage from "./FilterPage";
import NewRecTags from "./NewRecTags";
import "./LiquidGradient.css";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const [recs, setRecs] = useState([]);
  const [currCategory, setCategory] = useState("All");
  const [currArea, setArea] = useState("All");
  const [selectedTags, setSelectedTags] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getRecs() {
      setIsLoading(true);

      let query = supabase
        .from("recs_votes")
        .select("*")
        .order("upvotes", { ascending: false });

      if (currCategory !== "All") query = query.eq("category", currCategory);
      if (currArea !== "All") query = query.eq("area", currArea);

      const { data: recs, error } = await query;
      if (!error) setRecs(recs);
      else alert("There was an error fetching the recs");
      setIsLoading(false);
    }
    getRecs();
  }, [currCategory, currArea]);

  const handleViewBestOfNYLists = () => {
    navigate("/lists");
  };

  const handleDecideWithFriends = async () => {
    const uniqueLink = Math.random()
      .toString(36)
      .substr(2, 6);

    // Initialize the group in Supabase
    const { data, error } = await supabase
      .from("group_recs")
      .insert([{ link: uniqueLink }]);

    if (error) {
      alert("Error initializing group: " + error.message);
      return;
    }

    navigate(`/decide/${uniqueLink}`);
  };

  return (
    <div className="home-container">
      <div className="liquid-gradient-background"></div>
      <div>
        <main
          className="main"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2
            className="headline"
            style={{
              marginTop: -15,
              marginBottom: -20,
              textAlign: "center",
              fontSize: "24px",
              fontWeight: 400,
            }}
          >
            the tinder for recs
          </h2>
          <h2
            className="headline"
            style={{
              marginTop: -20,
              marginBottom: -40,
              textAlign: "center",
              fontWeight: 400,
            }}
          >
            recs from 40,000+ New Yorkers
          </h2>
          <FilterPage currCategory={currCategory} setCategory={setCategory} />

          <div
            className="new-feature-container"
            style={{ textAlign: "center" }}
          >
            <div
              style={{
                display: "inline-block",
                position: "relative",
                marginBottom: "20px",
              }}
            >
              <button
                className="view-best-button new-feature"
                onClick={handleViewBestOfNYLists}
              >
                view best of NY lists 🗽
              </button>
            </div>
            <div style={{ display: "inline-block", position: "relative" }}>
              <button
                className="decide-with-friends-button new-feature"
                onClick={handleDecideWithFriends}
              >
                <span className="new-feature-label">new!</span>
                decide with friends ✨
              </button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <a
                href="https://tally.so/r/nGLLlL"
                style={{ color: "#333", textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                give feedback
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Home;
