export const CATEGORIES = [
  { name: "All", color: "#ef4444", emoji: "🌃" },
  { name: "Bar", color: "#3b82f6", emoji: "🍸" },
  { name: "Cafe", color: "#16a34a", emoji: "☕️" },
  { name: "Attraction", color: "#eab308", emoji: "🎡" },
  { name: "Restaurant", color: "#ef4444", emoji: "🍽️" },
  { name: "Nightclub", color: "#db2777", emoji: "🍾" },
  { name: "Park", color: "#14b8a6", emoji: "🌳" },
  { name: "Bagel", color: "#f97316", emoji: "🥯" },
  { name: "Dessert", color: "#8b5cf6", emoji: "🍰" },
];

export const AREAS = [
  { name: "All", color: "grey" },
  { name: "East Village", color: "#3b82f6" },
  { name: "Tribeca", color: "#16a34a" },
  { name: "SoHo", color: "#ef4444" },
  { name: "Midtown", color: "#eab308" },
  { name: "Midtown East", color: "#16a34a" },
  { name: "Kips Bay", color: "#db2777" },
  { name: "Upper East Side", color: "#eab308" },
  { name: "Upper West Side", color: "#14b8a6" },
  { name: "Hell's Kitchen", color: "#db2777" },
  { name: "Lower East Side", color: "#f97316" },
  { name: "Hudson Yards", color: "#8b5cf6" },
  { name: "Chelsea", color: "#3b82f6" },
  { name: "West Village", color: "#16a34a" },
  { name: "NoMad", color: "#f97316" },
  { name: "FiDi", color: "#eab308" },
  { name: "Gramercy", color: "#db2777" },
  { name: "Williamsburg", color: "#14b8a6" },
  { name: "Greenwich Village", color: "#8b5cf6" },
  { name: "Other", color: "#f97316" },
];
