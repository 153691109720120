// FilterPage.js
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./AppContext";
import CategoryButtons from "./CategoryButtons";
import "./FilterPage.css";
import mixpanel from "mixpanel-browser";

function FilterPage() {
  const {
    currCategory,
    setCurrCategory,
    resetFilters,
    setShowAsCards,
    setShowOnboardingModal,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const handleContinueClick = () => {
    resetFilters();

    mixpanel.track("Card View", {
      Viewed: "true",
    });
    setShowAsCards(true);
    setShowOnboardingModal(true);
    navigate(`/category`);
  };

  return (
    <div className="filter-page">
      <h3
        style={{
          textAlign: "center",
          width: "100%",
          marginBottom: "15px",
          fontSize: "24px",
          fontWeight: 400,
          marginTop: "0px",
        }}
      >
        show me recs for
      </h3>
      <CategoryButtons />
      <button className="filter-continue-button" onClick={handleContinueClick}>
        let's go! 🔮
      </button>
    </div>
  );
}

export default FilterPage;
