import React, { useState, useEffect } from "react";
import { supabase } from "./supabase";
import { useAuth } from "./AuthContext";
import "./style.css"; // Ensure you have the CSS file for common styles
import "./AuthModal.css"; // Use the new AuthModal styles
import { FaGoogle } from "react-icons/fa"; // Add this import for the Google icon

function Auth({ onClose }) {
  const { setUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authMode, setAuthMode] = useState("login");

  const signInWithGoogle = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: window.location.origin,
      },
    });

    if (error) {
      console.error("Error signing in with Google:", error.message);
      alert(error.message);
    } else {
      console.log("Google sign-in initiated");
    }
  };

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        if (event === "SIGNED_IN") {
          setUser(session.user);
          onClose();
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [setUser, onClose]);

  async function handleLogin() {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      alert(error.message);
      return; // Exit the function if there's an error
    } else {
      setUser(data.user);
    }
  }

  async function handleRegister() {
    const { data, error } = await supabase.auth.signUp({ email, password });
    if (error) {
      alert(error.message);
      return; // Exit the function if there's an error
    } else {
      setUser(data.user);
    }

    // create a new user in the database only if registration is successful
    if (data.user) {
      const { error: dbError } = await supabase
        .from("users")
        .insert([{ id: data.user.id, email }]);

      if (dbError) {
        alert("There was an error creating your user in the database");
      }
    }
  }

  return (
    <div className="auth-modal-backdrop" onClick={onClose}>
      <div className="auth-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="auth-close-btn" onClick={onClose}>
          &times;
        </button>
        <div className="auth form-container">
          <h2>{authMode === "login" ? "login" : "register"}</h2>
          <button onClick={signInWithGoogle} className="google-signin-btn">
            <FaGoogle /> Sign in with Google
          </button>
          <div className="auth form-group">
            <label>email</label>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="auth form-group">
            <label>password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {authMode === "login" ? (
            <button onClick={handleLogin} className="auth">
              login
            </button>
          ) : (
            <button onClick={handleRegister} className="auth">
              register
            </button>
          )}
          <button
            onClick={() =>
              setAuthMode(authMode === "login" ? "register" : "login")
            }
            className="auth"
          >
            {authMode === "login" ? "switch to register" : "switch to login"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Auth;
