import React, { useEffect, useState } from "react";
import { supabase } from "./supabase";
import { useAuth } from "./AuthContext";
import Loader from "./Loader";
import RecsList from "./RecsList";

function Profile() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [submittedRecs, setSubmittedRecs] = useState([]);
  const [upvotedRecs, setUpvotedRecs] = useState([]);

  useEffect(() => {
    async function getProfileData() {
      setIsLoading(true);

      const { data: userRecs, error: userRecsError } = await supabase
        .from("recs_votes")
        .select("*")
        .eq("user_id", user.id);

      const { data: userUpvotes, error: userUpvotesError } = await supabase
        .from("votes")
        .select("rec_id")
        .eq("user_id", user.id)
        .eq("vote_type", "upvote");

      if (userRecsError || userUpvotesError) {
        alert("There was an error fetching your data");
        setIsLoading(false);
        return;
      }

      const upvotedRecIds = userUpvotes.map((vote) => vote.rec_id);
      const { data: upvotedRecsData, error: upvotedRecsError } = await supabase
        .from("recs_votes")
        .select("*")
        .in("id", upvotedRecIds);

      if (upvotedRecsError) {
        alert("There was an error fetching your upvoted recommendations");
        setIsLoading(false);
        return;
      }

      setSubmittedRecs(userRecs);
      setUpvotedRecs(upvotedRecsData);
      setIsLoading(false);
    }

    if (user) {
      getProfileData();
    }
  }, [user]);

  if (isLoading) return <Loader />;

  return (
    <div>
      <h1>Profile</h1>
      {user ? (
        <div>
          <p>Email: {user.email}</p>
          <p>Name: {user.user_metadata.full_name || user.email}</p>

          <h2>Your Submitted Recommendations</h2>
          {submittedRecs.length > 0 ? (
            <RecsList recs={submittedRecs} setRecs={setSubmittedRecs} />
          ) : (
            <p>You have not submitted any recommendations yet.</p>
          )}

          <h2>Your Upvoted Recommendations</h2>
          {upvotedRecs.length > 0 ? (
            <RecsList recs={upvotedRecs} setRecs={setUpvotedRecs} />
          ) : (
            <p>You have not upvoted any recommendations yet.</p>
          )}
        </div>
      ) : (
        <p>You need to log in to view your profile.</p>
      )}
    </div>
  );
}

export default Profile;
