import { createClient } from "@supabase/supabase-js";
const supabaseUrl = "https://rhkukmfxiryysjhaaucu.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJoa3VrbWZ4aXJ5eXNqaGFhdWN1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ2NTM3OTEsImV4cCI6MjAyMDIyOTc5MX0.Vc6ksEndxchcHAXCFMplwjfKBPpkNnNxTZbEd3h69v4";

export const supabase = createClient(supabaseUrl, supabaseKey, {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  realtime: {
    params: {
      eventsPerSecond: 10,
    },
  },
});
