import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import { supabase } from "./supabase";
import Loader from "./Loader";
import RecsList from "./RecsList";
import Deck from "./Deck";
import AreaFilter from "./AreaFilter";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./style.css";
import "./LiquidGradient.css";
import FilterModal from "./FilterModal";
import OnboardingModal from "./OnboardingModal";

function CategoryPage() {
  const {
    currCategory,
    filters,
    setFilters,
    isLoading,
    setIsLoading,
    recs,
    setRecs,
    originalRecs,
    setOriginalRecs,
    userLocation,
    setUserLocation,
    isSorting,
    setIsSorting,
    showAsCards,
    showOnboardingModal,
    setShowOnboardingModal,
  } = useContext(AppContext);

  const [showFilterModal, setShowFilterModal] = useState(false);

  const openFilterModal = () => {
    setShowFilterModal(true);
  };

  useEffect(() => {
    const fetchRecs = async () => {
      setIsLoading(true);
      try {
        // Fetch all recs for category rankings
        let allRecsQuery = supabase
          .from("recs_votes")
          .select("*")
          .order("lovevotes", { ascending: false });

        if (
          currCategory !== "All" &&
          currCategory !== "Near Me Now" &&
          currCategory !== "Discover"
        ) {
          allRecsQuery = allRecsQuery.eq("category", currCategory);
        }

        const { data: allRecs, error: allRecsError } = await allRecsQuery;

        if (allRecsError) throw allRecsError;

        // Calculate category rankings based on all recs
        const categoryRankings = {};
        allRecs.forEach((rec) => {
          if (!categoryRankings[rec.category]) {
            categoryRankings[rec.category] = [];
          }
          categoryRankings[rec.category].push(rec);
        });

        Object.values(categoryRankings).forEach((catRecs) => {
          catRecs.sort((a, b) => b.lovevotes - a.lovevotes);
          catRecs.forEach((rec, index) => {
            rec.categoryRank = index + 1;
          });
        });

        // Fetch filtered recs
        let filteredQuery = supabase
          .from("recs_votes")
          .select("*")
          .order("lovevotes", { ascending: false });

        if (
          currCategory !== "All" &&
          currCategory !== "Near Me Now" &&
          currCategory !== "Discover"
        ) {
          filteredQuery = filteredQuery.eq("category", currCategory);
        }

        if (filters.selectedArea !== "All") {
          filteredQuery = filteredQuery.eq("area", filters.selectedArea);
        }

        if (filters.selectedTags.length > 0) {
          filteredQuery = filteredQuery.or(
            filters.selectedTags
              .map((tag) => `categories.ilike.%${tag}%`)
              .join(",")
          );
        }

        const {
          data: filteredRecs,
          error: filteredError,
        } = await filteredQuery;

        if (filteredError) throw filteredError;

        // Apply price range filter
        const priceFilteredRecs = filteredRecs.filter((rec) => {
          const price_level = rec.price_level || 0;
          return (
            price_level >= filters.priceRange[0] &&
            price_level <= filters.priceRange[1]
          );
        });

        // Add category rankings to filtered recs
        const rankedRecs = priceFilteredRecs.map((rec) => ({
          ...rec,
          categoryRank:
            categoryRankings[rec.category].findIndex((r) => r.id === rec.id) +
            1,
        }));

        setOriginalRecs(rankedRecs);
        setRecs(rankedRecs);
      } catch (error) {
        console.error("Error fetching recommendations:", error);
        alert("There was an error fetching the recommendations");
      } finally {
        setIsLoading(false);
      }
    };

    fetchRecs();
  }, [currCategory, filters]);

  const applyFilters = (recs) => {
    // Apply area filter

    console.log("Start of applyFilters", recs);
    if (filters.selectedArea !== "All") {
      recs = recs.filter((rec) => rec.area === filters.selectedArea);
    }

    console.log("With area", recs);

    // Apply price range filter
    recs = recs.filter((rec) => {
      const price_level = rec.price_level || 0;
      return (
        price_level >= filters.priceRange[0] &&
        price_level <= filters.priceRange[1]
      );
    });

    console.log("With price level", recs);

    // Apply tag filters
    if (filters.selectedTags.length > 0) {
      recs = recs.filter((rec) =>
        filters.selectedTags.some((tag) => rec.categories.includes(tag))
      );
    }
    return recs;
  };

  useEffect(() => {
    console.log("button", filters.selectedButton);
    if (filters.selectedButton === "distance") {
      handleSortByDistance();
    } else if (filters.selectedButton === "lovevotes") {
      handleSortByLovevotes();
    } else if (filters.selectedButton === "random") {
      handleRandomize();
    }
  }, [filters]);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    function toRadians(degrees) {
      return degrees * (Math.PI / 180);
    }

    const R = 3958.8; // Radius of the Earth in miles
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
        Math.cos(toRadians(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  }

  function sortRecsByDistance(recs, userLat, userLon) {
    return recs
      .map((rec) => {
        const distance = calculateDistance(
          userLat,
          userLon,
          rec.latitude,
          rec.longitude
        );
        return { ...rec, distance };
      })
      .sort((a, b) => a.distance - b.distance);
  }

  function filterRecsByDistance(recs, maxDistance) {
    if (currCategory !== "Near Me Now") {
      return recs;
    }

    return recs.filter((rec) => rec.distance <= maxDistance);
  }

  function shuffleArray(array) {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  }

  function getUserLocation() {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        });
      } else {
        reject(new Error("Geolocation is not supported by your browser."));
      }
    });
  }

  function handleLocationAndSort(recs) {
    getUserLocation()
      .then((position) => {
        setUserLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });

        const sortedByDistance = sortRecsByDistance(
          recs,
          position.coords.latitude,
          position.coords.longitude
        );
        const filteredByDistance = filterRecsByDistance(
          sortedByDistance,
          filters.distanceFilter
        );
        setRecs(filteredByDistance);
        setOriginalRecs(sortedByDistance);
        setIsLoading(false);
        setIsSorting(false);
      })
      .catch((error) => {
        alert(
          "Could not get your location. Please ensure location services are enabled."
        );
        console.error("Error getting location:", error);
        setIsLoading(false);
        setIsSorting(false);
      });
  }

  function handleSortByDistance() {
    filters.selectedButton = "distance";
    setIsSorting(true);
    if (userLocation) {
      const sortedByDistance = sortRecsByDistance(
        originalRecs,
        userLocation.latitude,
        userLocation.longitude
      );
      const filteredByDistance = filterRecsByDistance(
        sortedByDistance,
        filters.distanceFilter
      );
      setRecs(filteredByDistance);
      setIsSorting(false);
    } else {
      handleLocationAndSort(originalRecs);
    }
  }

  function handleSortByLovevotes() {
    filters.selectedButton = "lovevotes";
    setIsSorting(true);
    const sortedByLovevotes = [...originalRecs].sort(
      (a, b) => b.lovevotes - a.lovevotes
    );
    const rankedRecs = sortedByLovevotes.map((rec, index) => ({
      ...rec,
      rank: index + 1,
    }));
    setRecs(rankedRecs);
    setIsSorting(false);
  }

  function handleRandomize() {
    filters.selectedButton = "random";
    const randomizedRecs = shuffleArray(originalRecs);
    setRecs(randomizedRecs);
  }

  function handleSliderChange(value) {
    setFilters({ ...filters, distanceFilter: value });
    if (currCategory === "Near Me Now" && userLocation) {
      const filteredByDistance = filterRecsByDistance(originalRecs, value);
      setRecs(filteredByDistance);
    }
  }

  return (
    <div className="category-page">
      <div className="liquid-gradient-background category-page-gradient"></div>
      <div className="category-content">
        {!showAsCards ? (
          <div>
            <AreaFilter
              currArea={filters.selectedArea}
              setArea={(area) => setFilters({ ...filters, selectedArea: area })}
            />
            <h2 style={{ textAlign: "center" }}>
              {currCategory === "All"
                ? "Best of All Categories"
                : `Best of New York ${currCategory}s`}
            </h2>
          </div>
        ) : null}
        {currCategory === "Near Me Now" && (
          <div className="slider-container">
            <div className="slider-labels">
              <span>&lt;1 mile</span>
              <span>5 miles</span>
            </div>
            <Slider
              min={1}
              max={5}
              step={0.1}
              value={filters.distanceFilter}
              onChange={handleSliderChange}
              trackStyle={{ height: "4px" }}
              handleStyle={{
                height: "12px",
                width: "12px",
                marginLeft: "-6px",
                marginTop: "-4px",
              }}
            />
          </div>
        )}
        {!showAsCards ? (
          <div className="filter-container">
            <button
              className={`filter-button ${
                filters.selectedButton === "distance"
                  ? "filter-button-selected"
                  : ""
              }`}
              onClick={handleSortByDistance}
              disabled={
                isLoading || isSorting || filters.selectedButton === "distance"
              }
            >
              {isSorting && filters.selectedButton === "distance"
                ? "Sorting..."
                : filters.selectedButton === "distance"
                ? "Sorted by Distance📍"
                : "Sort by Distance📍"}
            </button>
            <button
              className={`filter-button ${
                filters.selectedButton === "lovevotes"
                  ? "filter-button-selected"
                  : ""
              }`}
              onClick={handleSortByLovevotes}
              disabled={
                isLoading || isSorting || filters.selectedButton === "lovevotes"
              }
            >
              {filters.selectedButton === "lovevotes"
                ? "Sorted by Recs 🙌🏼"
                : "Sort by Recs 🙌🏼"}
            </button>
            <button
              className={`filter-button ${
                filters.selectedButton === "random"
                  ? "filter-button-selected"
                  : ""
              }`}
              onClick={handleRandomize}
              disabled={isLoading}
            >
              Randomize Order 🎲
            </button>
          </div>
        ) : null}
        {isLoading ? (
          <Loader />
        ) : showAsCards ? (
          <Deck
            recs={recs}
            setRecs={setRecs}
            saveRec={(name) => console.log(name)}
            openFilterModal={openFilterModal}
          />
        ) : (
          <RecsList recs={recs} setRecs={setRecs} />
        )}
        {showFilterModal && (
          <FilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filters={filters}
            setFilters={setFilters}
          />
        )}
        {showOnboardingModal && (
          <OnboardingModal
            isOpen={showOnboardingModal}
            onClose={() => setShowOnboardingModal(false)}
          />
        )}
      </div>
    </div>
  );
}

export default CategoryPage;
