import React from "react";

const DirectionsButton = ({ placeAddress, categoryColor }) => {
  const handleDirectionsClick = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      placeAddress
    )}`;
    window.open(url, "_blank");
  };

  return (
    <button
      onClick={handleDirectionsClick}
      style={{
        backgroundColor: categoryColor,
        color: "#fff",
        border: "none",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      Directions
    </button>
  );
};

export default DirectionsButton;
