import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { supabase } from "./supabase";
import { CATEGORIES } from "./constants";
import { useAuth } from "./AuthContext";
import "./Rec.css";
import RecModal from "./RecModal";

function GroupRec({
  rec,
  recs,
  setRecs,
  index,
  setVotedRecs,
  showSwipes,
  enableSwipe,
  rank,
}) {
  const { user } = useAuth();
  const [props, set] = useSpring(() => ({ x: 0, opacity: 1 }));
  const [removed, setRemoved] = useState(false);
  const SWIPE_THRESHOLD = 100;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRecClick = () => {
    setIsModalOpen(true);
  };

  const handleSwipe = async (direction) => {
    if (!enableSwipe) return;

    const swipeType = direction === "Left" ? "left_swipes" : "right_swipes";
    console.log(`Swiped ${direction} on ${rec.name}`);

    const link = window.location.pathname.split("/").pop();

    if (!rec.rec_id) {
      console.error("rec_id is undefined");
      return;
    }

    // Fetch current swipe counts
    const { data: currentRec, error: currentError } = await supabase
      .from("group_recs")
      .select("right_swipes, left_swipes")
      .eq("rec_id", rec.rec_id)
      .eq("link", link)
      .single();

    if (currentError) {
      console.error("Error fetching current swipe counts:", currentError);
      return;
    }

    console.log(
      `Current swipe counts for ${rec.name}: Right Swipes = ${currentRec.right_swipes}, Left Swipes = ${currentRec.left_swipes}`
    );

    // Initialize counts to 0 if they are null
    const rightSwipes = currentRec.right_swipes ?? 0;
    const leftSwipes = currentRec.left_swipes ?? 0;

    const updatedCount =
      swipeType === "right_swipes" ? rightSwipes + 1 : leftSwipes + 1;

    console.log(`Updated ${swipeType} count will be ${updatedCount}`);

    // Update swipe count in the database
    const { error: updateError } = await supabase
      .from("group_recs")
      .update({ [swipeType]: updatedCount })
      .eq("rec_id", rec.rec_id)
      .eq("link", link);

    if (updateError) {
      console.error("Error updating swipe count:", updateError);
      return;
    }

    console.log(
      `Successfully updated ${swipeType} count for ${rec.name} to ${updatedCount}`
    );

    // Add the swiped recommendation to the voted list with full details
    setVotedRecs((prevVotedRecs) => [
      ...prevVotedRecs,
      { ...rec, [swipeType]: updatedCount },
    ]);

    // Remove the recommendation from the recs list
    setRemoved(true);
    setTimeout(() => {
      setRecs((prevRecs) => prevRecs.filter((r) => r.rec_id !== rec.rec_id));
    }, 400); // Match this duration with the animation duration
  };

  const bind = useDrag(({ down, movement: [mx], direction: [xDir] }) => {
    if (!enableSwipe) return;

    const trigger = Math.abs(mx) > SWIPE_THRESHOLD;
    const dir = xDir < 0 ? -1 : 1;
    if (trigger) {
      set({
        x: dir * window.innerWidth,
        opacity: 0,
        config: { duration: 400 },
        onRest: () => handleSwipe(dir < 0 ? "Left" : "Right"),
      });
    } else {
      set({
        x: down ? mx : 0,
        opacity: down ? 1 : 1 - Math.abs(mx) / window.innerWidth,
        immediate: down,
      });
    }
  });

  if (removed) {
    return null;
  }

  // Add this function to get the rank color
  const getRankColor = (rank) => {
    switch (rank) {
      case 1:
        return "#bb9b31";
      case 2:
        return "rgb(149, 148, 148)"; // Silver
      case 3:
        return "#CD7F32"; // Bronze
      default:
        return categoryColor;
    }
  };

  // Add this function to get the rank emoji
  const getRankEmoji = (rank) => {
    switch (rank) {
      case 1:
        return "🏆";
      case 2:
        return "🥈";
      case 3:
        return "🥉";
      default:
        return "";
    }
  };

  // Get the category details, if available
  const category = CATEGORIES.find((el) => el.name === rec.category) || {};
  const categoryColor = category.color || "gray";
  const rankColor = getRankColor(rank);

  return (
    <animated.li
      className={`rec ${rank ? `rank-${rank}` : ""}`}
      {...bind()}
      style={{
        ...props,
        touchAction: enableSwipe ? "none" : "auto",
        borderColor: rank ? rankColor : categoryColor,
        borderWidth: rank ? "3px" : "2px",
        backgroundColor: rank ? `${rankColor}10` : `${categoryColor}10`,
      }}
    >
      {rank && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "5px",
            backgroundColor: rankColor,
          }}
        />
      )}
      <div onClick={handleRecClick}>
        <div className="tag_and_name">
          <p
            className="rec_name"
            style={{
              color: rank ? rankColor : categoryColor,
            }}
          >
            {rank && rank <= 3 ? getRankEmoji(rank) : `${index + 1}.`}{" "}
            {rec.name} | {rec.area}
          </p>
        </div>

        <p className="summary">{!rec.categories && rec.text}</p>
        <div className="summary">
          {rec.categories &&
            rec.categories.split(", ").map((category, idx) => (
              <p key={idx} className="category-tag">
                {category}
              </p>
            ))}
        </div>

        <div className="tag_and_vote_2">
          <span
            className="tag"
            style={{
              backgroundColor: categoryColor,
            }}
          >
            {rec.category}
          </span>
          <div className="rec-buttons">
            <button
              style={{
                borderColor: "#16a34a",
                borderWidth: 2,
                backgroundColor: `${"#16a34a"}40`,
              }}
            >
              <img
                src="/arrow-up-circle.png"
                style={{
                  paddingTop: 3,
                  paddingBottom: 0,
                  marginLeft: -2,
                  width: 15,
                }}
              />{" "}
              <span>{rec.upvotes}</span>
            </button>
            <button>🙌🏼 {rec.lovevotes}</button>
            <button
              style={{
                borderColor: "#ef4444",
                borderWidth: 2,
                backgroundColor: `${"#ef4444"}40`,
              }}
            >
              <img
                src="/arrow-down-circle.png"
                style={{
                  paddingTop: 3,
                  paddingBottom: 0,
                  marginLeft: -2,
                  width: 15,
                }}
              />{" "}
              {rec.downvotes}
            </button>
          </div>
        </div>
        {showSwipes && (
          <div className="group-rec-swipes">
            <div className="swipe-counts">
              <span className="swipe-count-left">
                {rec.left_swipes ?? 0} not feeling it
              </span>
              <span className="swipe-count-right">
                {rec.right_swipes ?? 0} wanna go
              </span>
            </div>
            <div className="swipe-bar">
              <div
                className="swipe-bar-left"
                style={{
                  width: `${((rec.left_swipes ?? 0) /
                    ((rec.left_swipes ?? 0) + (rec.right_swipes ?? 0))) *
                    100}%`,
                  backgroundColor: "#FF5A5F",
                }}
              />
              <div
                className="swipe-bar-right"
                style={{
                  width: `${((rec.right_swipes ?? 0) /
                    ((rec.left_swipes ?? 0) + (rec.right_swipes ?? 0))) *
                    100}%`,
                  backgroundColor: "rgba(22, 163, 74)",
                }}
              />
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
        <RecModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          rec={rec}
          setRecs={setRecs}
        />
      )}
    </animated.li>
  );
}

export default GroupRec;
