import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:8888/api" : "/api";

export const getYelpBusinessDetails = async (name, address) => {
  try {
    const response = await axios.get(`${BASE_URL}/yelpProxy`, {
      params: {
        name,
        address1: address,
        city: "New York",
        state: "NY",
        country: "US",
      },
    });
    if (
      response &&
      response.data.businesses &&
      response.data.businesses.length > 0
    ) {
      const business = response.data.businesses[0];
      return {
        ...response,
        yelpPrice: business.price ? business.price.length : null,
        yelpPhone: business.phone || null,
        yelpWebsite: business.url || null,
      };
    }
    return response;
  } catch (error) {
    console.error("Error fetching business details:", error);
    return null;
  }
};

export const getBusinessById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/yelpProxy`, {
      params: { id },
    });
    if (response) {
      console.log(response.data);
    }
    return response;
  } catch (error) {
    console.error("Error fetching business by ID:", error);
    return null;
  }
};
