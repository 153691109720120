import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaHome, FaList, FaDice } from "react-icons/fa";
import "./BottomNavBar.css";

const BottomNavBar = ({ setShowForm, user, handleDecideWithFriends }) => {
  const navigate = useNavigate();

  const handleShareClick = (e) => {
    e.preventDefault();
    if (user) {
      setShowForm((prev) => !prev);
    } else {
      alert("Please log in to share a new recommendation.");
    }
  };

  return (
    <div className="bottom-nav-bar">
      <Link to="/" className="nav-item">
        <FaHome />
        <span>home</span>
      </Link>
      <Link to="/lists" className="nav-item">
        <FaList />
        <span>list</span>
      </Link>
      <a href="#" className="nav-item" onClick={handleDecideWithFriends}>
        <FaDice />
        <span>decide</span>
      </a>
      <a href="#" className="nav-item" onClick={handleShareClick}>
        <img src="/plus-icon.png" alt="Share" className="nav-icon" />
        <span>share rec</span>
      </a>
    </div>
  );
};

export default BottomNavBar;
