export const isOpenNow = (periods) => {
  if (!periods || !Array.isArray(periods) || periods.length === 0) {
    return false;
  }

  const now = new Date();
  const day = now.getDay(); // Sunday - Saturday: 0 - 6
  const currentTime = now.getHours() * 100 + now.getMinutes(); // Convert current time to HHMM format

  return periods.some((period) => {
    if (!period || !period.open || !period.close) {
      return false;
    }

    if (period.open.day === day && period.close.day === day) {
      return (
        currentTime >= parseInt(period.open.time) &&
        currentTime < parseInt(period.close.time)
      );
    } else if (period.open.day === day && period.close.day !== day) {
      return currentTime >= parseInt(period.open.time);
    } else if (period.open.day !== day && period.close.day === day) {
      return currentTime < parseInt(period.close.time);
    }
    return false;
  });
};
