import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./PriceFilter.css";

const PriceFilter = ({ priceRange, setPriceRange }) => {
  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const marks = {
    1: "$",
    2: "$$",
    3: "$$$",
    4: "$$$$",
  };

  return (
    <div className="price-filter">
      <Slider
        range
        min={1}
        max={4}
        marks={marks}
        step={1}
        value={priceRange}
        onChange={handlePriceChange}
        railStyle={{ backgroundColor: "#e4e4e4", height: 4 }}
        trackStyle={[{ backgroundColor: "#ff5a5f", height: 4 }]}
        handleStyle={[
          {
            borderColor: "#ff5a5f",
            height: 28,
            width: 28,
            marginLeft: -14,
            marginTop: -12,
            backgroundColor: "#fff",
          },
          {
            borderColor: "#ff5a5f",
            height: 28,
            width: 28,
            marginLeft: -14,
            marginTop: -12,
            backgroundColor: "#fff",
          },
        ]}
        dotStyle={{ display: "none" }}
      />
    </div>
  );
};

export default PriceFilter;
