// RecsList.js
import React, { useState } from "react";
import Rec from "./Rec";

function RecsList({ recs, setRecs }) {
  const [savedRecs, setSavedRecs] = useState([]);

  const saveRec = (recName) => {
    setSavedRecs((prevSavedRecs) => [...prevSavedRecs, recName]);
  };

  if (recs.length === 0) {
    return <p>No recommendations. Add one!</p>;
  }

  const sortedRecs = recs;
  return (
    <section>
      <ul className="rec-list">
        {sortedRecs.map((rec, index) => (
          <Rec
            key={rec.id}
            rec={rec}
            recs={recs}
            setRecs={setRecs}
            index={index}
            saveRec={saveRec}
          />
        ))}
      </ul>
      <div className="saved-recs">
        <h2>Saved Recommendations</h2>
        <ul>
          {savedRecs.map((recName, index) => (
            <li key={index}>{recName}</li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default RecsList;
