import React from "react";
import "./OnboardingModal.css";
import { FaHandPaper, FaThumbsUp, FaInfoCircle } from "react-icons/fa";

const OnboardingModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const steps = [
    {
      icon: <FaHandPaper />,
      title: "Swipe",
      description: "Swipe right if you're interested, left if not",
    },
    {
      icon: <FaThumbsUp />,
      title: "Vote",
      description: "Upvote or downvote places you've been to",
    },
    {
      icon: "🙌🏼",
      title: "Recommend",
      description: "Love a place? Recommend it to others",
    },
    {
      icon: (
        <FaInfoCircle style={{ verticalAlign: "-2px", marginRight: "-2px" }} />
      ),
      title: "Explore",
      description: "Tap on cards to see more details",
    },
  ];

  return (
    <div className="onboarding-modal-backdrop" onClick={onClose}>
      <div
        className="onboarding-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="welcome-header">Welcome to sugar!</h2>
        <p className="welcome-subheader">Discover your next favorite spot</p>

        <div className="onboarding-steps">
          {steps.map((step, index) => (
            <div key={index} className="onboarding-step">
              <div className="step-content">
                <div className="step-title">
                  <span className="step-icon">{step.icon}</span>
                  <h3>{step.title}</h3>
                </div>
                <p>{step.description}</p>
              </div>
            </div>
          ))}
        </div>

        <button className="lets-swipe-button" onClick={onClose}>
          Let's Explore!
          <img
            src="/fingers-logo.png"
            alt="Sugar logo"
            className="button-logo"
          />
        </button>
      </div>
    </div>
  );
};

export default OnboardingModal;
