import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { CATEGORIES } from "./constants";
import { supabase } from "./supabase";
import { useDrag } from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";
import { useAuth } from "./AuthContext";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Card.css"; // Ensure you have this CSS file for custom styles
import Tag from "./Tag";
import "./Tag.css";
import { AREAS } from "./constants";
import TagFilter from "./TagFilter";
import DirectionsButton from "./DirectionsButton"; // Import DirectionsButton
import RecModal from "./RecModal"; // Import the RecModal component
import { FaStar, FaDollarSign, FaCircle, FaInfoCircle } from "react-icons/fa";
import { isOpenNow } from "./dateUtils"; // Add this import
import TipsAndTagsModal from "./TipsAndTagsModal"; // Import the TipsAndTagsModal component
import { adjustColor } from "./utils"; // Import the adjustColor function
import mixpanel from "mixpanel-browser";

function Card({
  rec,
  setRecs,
  index,
  saveRec,
  isTopRecommendation,
  handleSwipeRight,
}) {
  const { user } = useAuth();
  const [voteUpLoading, setVoteUpLoading] = useState(false);
  const [voteDownLoading, setVoteDownLoading] = useState(false);
  const [voteLoveLoading, setVoteLoveLoading] = useState(false);
  const [props, set] = useSpring(() => ({ x: 0, y: 0, scale: 1, opacity: 1 }));
  const [isVisible, setIsVisible] = useState(true);
  const sliderRef = useRef(null);
  const [swipeDirection, setSwipeDirection] = useState(null);
  const [xPos, setXPos] = useState(0);
  const [photos, setPhotos] = useState([]);
  const [apiCallCount, setApiCallCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [{ x }, api] = useSpring(() => ({ x: 0 })); // Use the x value from the spring
  const [isTipsAndTagsModalOpen, setIsTipsAndTagsModalOpen] = useState(false);

  const SWIPE_THRESHOLD = 30; // Increased threshold for better desktop experience

  useEffect(() => {
    if (!isTopRecommendation) return;

    const fetchPhotos = async () => {
      if (rec.place_id) {
        try {
          const response = await axios.get(`/.netlify/functions/fetchPhotos`, {
            params: {
              place_id: rec.place_id,
            },
          });

          if (response.data.photoUrls) {
            setPhotos(response.data.photoUrls); // Store all photo URLs
            setApiCallCount((prevCount) => {
              const newCount = prevCount + 1;
              console.log(`API called ${newCount} times`);
              return newCount;
            });
          } else {
            handleFallbackPhotos();
          }
        } catch (error) {
          console.error("Error fetching photos from Netlify function:", error);
          handleFallbackPhotos();
        }
      } else {
        handleFallbackPhotos();
      }
    };

    const handleFallbackPhotos = () => {
      if (rec.photo_urls) {
        const fallbackPhotos = rec.photo_urls.split(",");
        setPhotos(fallbackPhotos); // Store all fallback photo URLs
      } else {
        console.log("No photos available for this recommendation.");
      }
    };

    fetchPhotos();
  }, [rec.place_id, rec.photo_urls, isTopRecommendation]);

  const logSwipe = (direction, rec) => {
    console.log(
      `Swipe triggered: ${direction}, Recommendation ID: ${
        rec.id
      }, Time: ${new Date().toISOString()}`
    );
    mixpanel.track("Rec Interaction", {
      Swiped: direction,
      Rec_ID: rec.id,
    });
  };

  const handleSwipe = (direction) => {
    logSwipe(direction, rec);
    if (direction === "Left") {
      // Remove the rec from the list
      setRecs((prevRecs) => prevRecs.filter((r) => r.id !== rec.id));
    } else if (direction === "Right") {
      handleSwipeRight(rec); // Call the handleSwipeRight function
      setRecs((prevRecs) => prevRecs.filter((r) => r.id !== rec.id));
    }

    // Set visibility to false to make the card disappear
    setIsVisible(false);
  };

  const bind = useDrag(
    ({ down, movement: [mx], direction: [xDir], velocity }) => {
      const trigger = Math.abs(mx) > SWIPE_THRESHOLD; // Trigger swipe if past threshold and has sufficient velocity
      const dir = xDir < 0 ? -1 : 1; // Direction: -1 for left, 1 for right
      if (!down && trigger) {
        api.start({
          x: dir * window.innerWidth,
          y: 0,
          scale: 1,
          opacity: 0,
          config: { duration: 400 },
          onRest: () => handleSwipe(dir < 0 ? "Left" : "Right"),
        });
      } else {
        api.start({
          x: down ? mx : 0,
          y: 0,
          scale: down ? 1.1 : 1,
          opacity: down ? 1 : 1 - Math.abs(mx) / window.innerWidth,
          immediate: down,
        });
        setXPos(down ? mx : 0);
        setSwipeDirection(down ? (mx < 0 ? "Left" : "Right") : null);
      }
    },
    { filterTaps: true }
  );

  async function handleVote(voteType) {
    if (!user) {
      alert("You need to log in to vote.");
      return;
    }

    if (rec.user_id === user.id) {
      alert("You cannot vote on your own recommendation.");
      return;
    }

    if (voteType === "upvote") setVoteUpLoading(true);
    if (voteType === "downvote") setVoteDownLoading(true);
    if (voteType === "lovevote") setVoteLoveLoading(true);

    try {
      const { data: existingVote, error: existingVoteError } = await supabase
        .from("votes")
        .select("*")
        .eq("user_id", user.id)
        .eq("rec_id", rec.id)
        .eq("vote_type", voteType)
        .maybeSingle();

      if (existingVoteError) throw existingVoteError;

      if (!existingVote) {
        const { error: insertVoteError } = await supabase
          .from("votes")
          .insert([{ user_id: user.id, rec_id: rec.id, vote_type: voteType }]);

        if (insertVoteError) throw insertVoteError;

        const { data: updatedRec, error: updateRecError } = await supabase
          .from("recs_votes")
          .update({ [`${voteType}s`]: rec[`${voteType}s`] + 1 })
          .eq("id", rec.id)
          .select()
          .single();

        if (updateRecError) throw updateRecError;

        setRecs((prevRecs) =>
          prevRecs.map((r) => (r.id === updatedRec.id ? updatedRec : r))
        );

        if (voteType === "lovevote") {
          setIsTipsAndTagsModalOpen(true);
        }

        mixpanel.track("Rec Interaction", {
          button_clicked: voteType,
          Rec_ID: rec.id,
        });
      } else {
        alert("You have already voted.");
      }
    } catch (error) {
      console.error("Error in handleVote:", error);
      alert(`There was an error processing your vote: ${error.message}`);
    } finally {
      setVoteUpLoading(false);
      setVoteDownLoading(false);
      setVoteLoveLoading(false);
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true, // Ensure the height adjusts to the content
    arrows: true,
  };

  const handleImageClick = (event) => {
    const imageContainer = event.target.getBoundingClientRect();
    const clickPositionX = event.clientX - imageContainer.left;
    const containerWidth = imageContainer.width;
    if (clickPositionX < containerWidth / 2) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  };

  const categoryColor = CATEGORIES.find((el) => el.name === rec.category).color;

  // Add this near the top of the component
  const openStatus = isOpenNow(
    rec.opening_hours ? JSON.parse(rec.opening_hours).periods : []
  );

  const handleCardClick = (event) => {
    // Only open modal if card is at its original position (x === 0)
    if (x.get() === 0) {
      // Ignore clicks on buttons or the photo container
      if (
        event.target.closest(".vote-buttons") ||
        event.target.closest(".card-buttons") ||
        event.target.closest(".photo-container")
      ) {
        return;
      }
      setIsModalOpen(true);
      mixpanel.track("Rec Interaction", {
        more_info: "clicked",
        Rec_ID: rec.id,
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    isVisible && (
      <>
        <animated.div
          className={`card ${
            swipeDirection === "Left"
              ? "card-swipe-left"
              : swipeDirection === "Right"
              ? "card-swipe-right"
              : ""
          }`}
          {...(isModalOpen ? {} : bind())}
          style={{
            ...props,
            x, // Use the x value from the spring
            touchAction: "none",
            borderColor: categoryColor,
            borderWidth: "5px",
            backgroundImage: "linear-gradient(135deg, white, beige, #cfb287d9)",
            zIndex: 100 - index, // Ensure the top card has the highest z-index
            background: swipeDirection
              ? `linear-gradient(to ${swipeDirection.toLowerCase()}, rgba(${
                  swipeDirection === "Left" ? "255, 0, 0" : "22, 163, 74"
                }, ${Math.min(Math.abs(xPos) / window.innerWidth, 1)}), rgba(${
                  swipeDirection === "Left" ? "255, 0, 0" : "22, 163, 74"
                }, 0)), white`
              : "white",
            "--category-color": categoryColor, // Add this line to set the CSS variable
            "--category-color-light": adjustColor(categoryColor, 25),
          }}
          onClick={handleCardClick}
        >
          <span
            className="card_tag"
            style={{
              backgroundColor: categoryColor,
            }}
          >
            {rec.categoryRank ? `#${rec.categoryRank}` : ""} {rec.category}
          </span>
          <div className="photo-container" onClick={handleImageClick}>
            {isTopRecommendation && (
              <>
                <Slider {...settings} ref={sliderRef}>
                  {photos.length > 0 ? (
                    photos.map((url, idx) => (
                      <div key={idx} className="card-image">
                        <img
                          src={url}
                          alt={`Photo ${idx + 1}`}
                          className="photo"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "10px",
                          }}
                        />
                        <div
                          className="photo-gradient"
                          style={{
                            background: `linear-gradient(to top, ${categoryColor} 20%, transparent)`,
                          }}
                        ></div>
                      </div>
                    ))
                  ) : (
                    <div className="no-photos">
                      <p>loading 😋</p>
                    </div>
                  )}
                </Slider>
                <div className="card-info-row-overlay">
                  <div className="card-status">
                    <FaCircle
                      className={`status-icon ${
                        openStatus ? "open" : "closed"
                      }`}
                    />
                    {openStatus ? "open" : "closed"}
                  </div>
                  {rec.rating && (
                    <div className="card-rating">
                      <FaStar className="icon" /> {rec.rating} (
                      {rec.user_ratings_total > 999
                        ? `${(rec.user_ratings_total / 1000).toFixed(1)}k`
                        : rec.user_ratings_total}
                      {""})
                    </div>
                  )}
                  {rec.price_level !== null && (
                    <div className="card-price">
                      <FaDollarSign className="icon" />{" "}
                      {"$".repeat(rec.price_level)}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <p className="card_name">{rec.name}</p>

          <p className="card_summary">{!rec.categories && rec.text}</p>
          <div className="card_summary">
            {rec.categories &&
              rec.categories.split(", ").map((category, idx) => (
                <p key={idx} className="category-tag">
                  {category}
                </p>
              ))}
          </div>
          <div className="card_tag_and_vote">
            <div className="vote-buttons">
              <button
                style={{
                  borderColor: "#ef4444",
                  borderWidth: 2,
                  backgroundColor: `${"#ef4444"}80`,
                }}
                onClick={() => handleVote("downvote")}
                disabled={voteDownLoading}
              >
                ↓ {rec.downvotes}
              </button>
              <button
                onClick={() => handleVote("lovevote")}
                disabled={voteLoveLoading}
              >
                🙌🏼 {rec.lovevotes}
              </button>
              <button
                style={{
                  borderColor: "#16a34a",
                  borderWidth: 2,
                  backgroundColor: `${"#16a34a"}80`,
                }}
                onClick={() => handleVote("upvote")}
                disabled={voteUpLoading}
              >
                ↑ {rec.upvotes}
              </button>
            </div>
          </div>
          <div className="card-buttons">
            <DirectionsButton
              placeAddress={rec.address}
              categoryColor={categoryColor} // Pass categoryColor to DirectionsButton
            />
            <button
              style={{ backgroundColor: categoryColor }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent card click event
                setIsModalOpen(true);
              }}
            >
              More Info
            </button>
          </div>
        </animated.div>
        {isModalOpen && (
          <RecModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            rec={rec}
            setRecs={setRecs}
          />
        )}
        {isTipsAndTagsModalOpen && (
          <TipsAndTagsModal
            recId={rec.id}
            setRecs={setRecs}
            setShowModal={setIsTipsAndTagsModalOpen}
          />
        )}
      </>
    )
  );
}

export default Card;
