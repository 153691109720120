import React, { useState } from "react";
import "./VibeTags.css";

function VibeTags({ recId, tagCounts, setRecs }) {
  const [clickedTags, setClickedTags] = useState({});
  const [isAdding, setIsAdding] = useState(false);
  const [newTag, setNewTag] = useState("");

  if (typeof setRecs !== "function") {
    console.error("setRecs is not a function:", setRecs);
    return null;
  }

  const handleTagClick = (tag) => {
    if (clickedTags[tag]) return;

    const updatedTagCounts = { ...tagCounts, [tag]: tagCounts[tag] + 1 };

    setRecs((prevRecs) =>
      prevRecs.map((rec) =>
        rec.id === recId ? { ...rec, tag_counts: updatedTagCounts } : rec
      )
    );

    setClickedTags((prev) => ({ ...prev, [tag]: true }));
  };

  const handleAddNewTag = () => {
    if (newTag.trim() !== "" && !tagCounts.hasOwnProperty(newTag.trim())) {
      const updatedTagCounts = { ...tagCounts, [newTag.trim()]: 1 };

      setRecs((prevRecs) =>
        prevRecs.map((rec) =>
          rec.id === recId ? { ...rec, tag_counts: updatedTagCounts } : rec
        )
      );

      setClickedTags((prev) => ({ ...prev, [newTag.trim()]: true }));
      setNewTag("");
      setIsAdding(false);
    }
  };

  const sortedTags = Object.entries(tagCounts)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5);

  return (
    <div className="vibe-tags-container">
      <div className="vibe-tags">
        <div className="highlight-tags">
          {sortedTags.map(([tag, count]) => (
            <button
              key={tag}
              className={`tag-bubble ${clickedTags[tag] ? "clicked" : ""}`}
              onClick={() => handleTagClick(tag)}
              disabled={clickedTags[tag]}
            >
              {tag}
              <span className="count-wrapper">
                <span className="count">{count}</span>
              </span>
            </button>
          ))}
          {isAdding ? (
            <div className="new-tag-input-container">
              <input
                type="text"
                value={newTag}
                onChange={(e) => setNewTag(e.target.value)}
                className="new-tag-input"
                placeholder="Enter new tag"
              />
              <button onClick={handleAddNewTag} className="new-tag-submit">
                <img
                  src="/check-btn-white.png"
                  alt="Submit"
                  className="check-btn-icon"
                />
              </button>
            </div>
          ) : (
            <button
              className="tag-bubble add-new-tag"
              onClick={() => setIsAdding(true)}
            >
              <svg
                className="add-btn-icon"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
              </svg>
              Add new tag
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default VibeTags;
