import React, { useState, useEffect, useRef } from "react";
import GroupRecsList from "./GroupRecsList";
import GroupRec from "./GroupRec";
import { useParams } from "react-router-dom";
import { supabase } from "./supabase";
import { useAuth } from "./AuthContext";
import "./DecideWithFriendsPage.css"; // Import the new CSS file

const DecideWithFriendsPage = () => {
  const { link } = useParams();
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [recs, setRecs] = useState([]);
  const [votedRecs, setVotedRecs] = useState([]);
  const searchContainerRef = useRef(null);

  useEffect(() => {
    async function fetchRecs() {
      const { data: groupRecs, error: groupRecsError } = await supabase
        .from("group_recs")
        .select("rec_id, right_swipes, left_swipes")
        .eq("link", link);

      if (groupRecsError) {
        console.error("Error fetching group recs:", groupRecsError);
        return;
      }

      const validGroupRecs = groupRecs.filter((rec) => rec.rec_id);

      if (validGroupRecs.length > 0) {
        const recIds = validGroupRecs.map((rec) => rec.rec_id);

        const { data: recsData, error: recsError } = await supabase
          .from("recs_votes")
          .select("*")
          .in("id", recIds);

        if (recsError) {
          console.error("Error fetching recs:", recsError);
          return;
        }

        const mappedRecs = recsData.map((rec) => {
          const groupRec = validGroupRecs.find((gr) => gr.rec_id === rec.id);
          return {
            ...rec,
            rec_id: rec.id,
            right_swipes: groupRec.right_swipes,
            left_swipes: groupRec.left_swipes,
          };
        });

        setRecs(mappedRecs.filter((rec) => rec.name)); // Filter out any invalid or empty recommendations
      } else {
        setRecs([]);
      }
    }

    fetchRecs();

    const subscription = supabase
      .channel(`public:group_recs:link=eq.${link}`)
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "group_recs",
          filter: `link=eq.${link}`,
        },
        (payload) => {
          const newRec = payload.new;
          setRecs((prevRecs) => {
            const recExists = prevRecs.some(
              (rec) => rec.rec_id === newRec.rec_id
            );
            return recExists ? prevRecs : [...prevRecs, newRec];
          });
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, [link]);

  useEffect(() => {
    if (searchTerm.length > 0) {
      const fetchSearchResults = async () => {
        const { data, error } = await supabase
          .from("recs_votes")
          .select("id, name")
          .ilike("name", `%${searchTerm}%`);

        if (error) {
          console.error("Error fetching search results:", error);
        } else {
          setSearchResults(data);
        }
      };

      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleAddRecommendation = async (recId) => {
    const { data: newRecData, error: newRecError } = await supabase
      .from("recs_votes")
      .select("*")
      .eq("id", recId)
      .single();

    if (newRecError) {
      console.error("Error fetching new recommendation:", newRecError);
      return;
    }

    if (newRecData && newRecData.name) {
      // Ensure newRecData is valid
      const { data, error } = await supabase
        .from("group_recs")
        .insert([{ link, rec_id: recId }]);

      if (error) {
        console.error("Error adding recommendation:", error);
      } else {
        setRecs((prevRecs) => [...prevRecs, { ...newRecData, rec_id: recId }]);
        setSearchTerm("");
        setSearchResults([]);
      }
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const sortedVotedRecs = votedRecs.slice().sort((a, b) => {
    const scoreA = (a.right_swipes ?? 0) - (a.left_swipes ?? 0);
    const scoreB = (b.right_swipes ?? 0) - (b.left_swipes ?? 0);
    return scoreB - scoreA;
  });

  const handleShare = async () => {
    const shareUrl = window.location.href;
    const shareText = "Join me to decide where to go!";

    if (navigator.share) {
      try {
        await navigator.share({
          title: "Decide with Friends",
          text: shareText,
          url: shareUrl,
        });
        console.log("Shared successfully");
      } catch (error) {
        console.log("Error sharing:", error);
      }
    } else {
      // Fallback for browsers that don't support Web Share API
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert("Link copied to clipboard!");
      } catch (err) {
        console.error("Failed to copy: ", err);
        alert(`Please copy this link manually: ${shareUrl}`);
      }
    }
  };

  return (
    <div>
      <h2 style={{ marginTop: "-10px" }}>Decide with Friends</h2>
      <div className="share-container">
        <p>Share this link with your friends:</p>
        <button onClick={handleShare} className="share-button">
          Share Link
        </button>
      </div>

      <div className="search-container" ref={searchContainerRef}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for recommendations..."
          className="search-box"
        />
        {searchResults.length > 0 && (
          <div className="search-results">
            {searchResults.map((rec) => (
              <div
                key={rec.id}
                className="search-result-item"
                onClick={() => handleAddRecommendation(rec.id)}
              >
                {rec.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="instructions-container">
        <p>add different spots & click to learn more.</p>
        <p>swipe to vote and collectively decide.</p>
      </div>
      <GroupRecsList
        recs={recs}
        setRecs={setRecs}
        setVotedRecs={setVotedRecs}
        showSwipes={false}
        enableSwipe={true}
      />
      {sortedVotedRecs.length > 0 && (
        <section>
          <h2>Voted On</h2>
          <ul className="rec-list">
            {sortedVotedRecs.map((rec, index) => (
              <GroupRec
                key={rec.rec_id}
                rec={rec}
                recs={sortedVotedRecs}
                setRecs={setVotedRecs}
                index={index}
                showSwipes={true}
                enableSwipe={false}
                rank={index < 3 ? index + 1 : null}
              />
            ))}
          </ul>
        </section>
      )}
      {/* Add other components here */}
    </div>
  );
};

export default DecideWithFriendsPage;
