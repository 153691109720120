import React, { useState } from "react";
import { supabase } from "./supabase";
import { useAuth } from "./AuthContext";
import { FaTimes } from "react-icons/fa";
import "./NewRecModal.css";
import NewRecTip from "./NewRecTip";
import NewRecTags from "./NewRecTags";

function TipsAndTagsModal({ setRecs, setShowModal, recId }) {
  const { user } = useAuth();
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(1);
  const [tip, setTip] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  async function handleSubmitTip() {
    if (!tip) {
      alert("Please enter a tip before submitting.");
      return;
    }

    setIsUploading(true);

    // Fetch the current tips
    const { data: currentTips, error: fetchError } = await supabase
      .from("recs_votes")
      .select("tips")
      .eq("id", recId)
      .single();

    if (fetchError) {
      alert(`Error fetching current tips: ${fetchError.message}`);
      console.error("Error fetching tips:", fetchError.message);
      setIsUploading(false);
      return;
    }

    console.log("Current tips:", currentTips);

    // Prepare the new tip object
    const newTip = {
      text: tip,
      user_id: user.id,
      created_at: new Date().toISOString(),
    };

    // Append the new tip to the existing tips array
    const updatedTips = currentTips.tips
      ? [...currentTips.tips, newTip]
      : [newTip];

    // Update the tips column with the new array
    const { error: updateError } = await supabase
      .from("recs_votes")
      .update({ tips: updatedTips })
      .eq("id", recId);

    if (updateError) {
      alert(`Error submitting your tip: ${updateError.message}`);
      console.error("Error submitting tip:", updateError.message);
    }
    setIsUploading(false);
    setStep(2);
  }

  async function handleFinalSubmit() {
    setIsUploading(true);

    try {
      // 1. Check for existing lovevote
      const { data: existingVote, error: existingVoteError } = await supabase
        .from("votes")
        .select("*")
        .eq("user_id", user.id)
        .eq("rec_id", recId)
        .eq("vote_type", "lovevote")
        .single();

      if (existingVoteError && existingVoteError.code !== "PGRST116") {
        throw new Error(
          `Error checking existing vote: ${existingVoteError.message}`
        );
      }

      // 2. If no existing lovevote, add one
      if (!existingVote) {
        const { error: insertVoteError } = await supabase
          .from("votes")
          .insert([{ user_id: user.id, rec_id: recId, vote_type: "lovevote" }]);

        if (insertVoteError) {
          throw new Error(
            `Error registering lovevote: ${insertVoteError.message}`
          );
        }

        // Update lovevotes count in recs_votes table
        const { data: updatedRec, error: updateRecError } = await supabase
          .from("recs_votes")
          .update({
            lovevotes: supabase.rpc("increment", {
              row_id: recId,
              column_name: "lovevotes",
            }),
          })
          .eq("id", recId)
          .select();

        if (updateRecError) {
          throw new Error(
            `Error updating lovevotes count: ${updateRecError.message}`
          );
        }

        // Update local state
        setRecs((prevRecs) =>
          prevRecs.map((r) =>
            r.id === recId ? { ...r, lovevotes: (r.lovevotes || 0) + 1 } : r
          )
        );
      }

      // 3. Add tip
      if (tip) {
        const { data: currentTips, error: fetchError } = await supabase
          .from("recs_votes")
          .select("tips")
          .eq("id", recId)
          .single();

        if (fetchError) {
          throw new Error(`Error fetching current tips: ${fetchError.message}`);
        }

        const newTip = {
          text: tip,
          user_id: user.id,
          created_at: new Date().toISOString(),
        };

        const updatedTips = currentTips.tips
          ? [...currentTips.tips, newTip]
          : [newTip];

        const { error: updateTipError } = await supabase
          .from("recs_votes")
          .update({ tips: updatedTips })
          .eq("id", recId);

        if (updateTipError) {
          throw new Error(`Error updating tips: ${updateTipError.message}`);
        }
      }

      // 4. Update tags
      if (selectedTags.length > 0) {
        const { data: currentRec, error: fetchRecError } = await supabase
          .from("recs_votes")
          .select("tag_counts")
          .eq("id", recId)
          .single();

        if (fetchRecError) {
          throw new Error(
            `Error fetching current rec: ${fetchRecError.message}`
          );
        }

        const updatedTagCounts = { ...(currentRec.tag_counts || {}) };
        for (const tag of selectedTags) {
          updatedTagCounts[tag] = (updatedTagCounts[tag] || 0) + 1;
        }

        const { error: updateTagError } = await supabase
          .from("recs_votes")
          .update({ tag_counts: updatedTagCounts })
          .eq("id", recId);

        if (updateTagError) {
          throw new Error(`Error updating tags: ${updateTagError.message}`);
        }
      }

      setShowModal(false);
    } catch (error) {
      console.error("Error in handleFinalSubmit:", error);
      alert(error.message);
    } finally {
      setIsUploading(false);
    }
  }

  return (
    <div className="new-rec-modal-backdrop" onClick={() => setShowModal(false)}>
      <div
        className="new-rec-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="new-rec-modal-header">
          <button
            className="new-rec-close-btn"
            onClick={() => setShowModal(false)}
          >
            <FaTimes />
          </button>
        </div>
        <form className="new-rec-form" onSubmit={(e) => e.preventDefault()}>
          {step === 1 && (
            <div className="form-group">
              <NewRecTip setTip={setTip} />
              <button
                className="submit-button"
                onClick={handleSubmitTip}
                disabled={isUploading}
              >
                {isUploading ? "Submitting..." : "Submit Tip"}
              </button>
            </div>
          )}
          {step === 2 && (
            <div className="form-group">
              <NewRecTags
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
              />
              <button
                className="submit-button"
                onClick={handleFinalSubmit}
                disabled={isUploading}
              >
                {isUploading ? "Finalizing..." : "Finish"}
              </button>
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default TipsAndTagsModal;
