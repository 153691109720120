import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CardHeader.css";
import FilterModal from "./FilterModal";
import mixpanel from "mixpanel-browser";

const CardHeader = ({ onSortByDistance, onSortByUpvotes }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeSort, setActiveSort] = useState("upvotes");
  const [isSorting, setIsSorting] = useState(false); // New state for sorting status

  const openModal = () => {
    setIsModalOpen(true);
    mixpanel.track("Card View", {
      Filters: "opened",
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSortChange = (sortType) => {
    setActiveSort(sortType);
    if (sortType === "distance") {
      setIsSorting(true); // Set sorting status to true
      onSortByDistance().then(() => {
        setIsSorting(false); // Set sorting status to false after sorting is complete
        mixpanel.track("Card View", {
          Sort: sortType,
        });
      });
    } else {
      onSortByUpvotes();
      mixpanel.track("Card View", {
        Sort: sortType,
      });
    }
  };

  return (
    <div className="card-header">
      <Link to="/" className="header-btn">
        <img src="/back-btn.png" alt="Back" />
      </Link>
      <div className="header-title">
        <div className="toggle-container">
          <div className="toggle-wrapper">
            <input
              type="radio"
              id="for-you"
              name="toggle"
              checked={activeSort === "upvotes"}
              onChange={() => handleSortChange("upvotes")}
            />
            <input
              type="radio"
              id="near-me"
              name="toggle"
              checked={activeSort === "distance"}
              onChange={() => handleSortChange("distance")}
            />
            <label htmlFor="for-you">For you</label>
            <label htmlFor="near-me">
              {isSorting ? "Sorting..." : "Near me now"}{" "}
              {/* Update text based on sorting status */}
            </label>
            <div className="toggle-slider"></div>
          </div>
        </div>
      </div>
      <button className="header-btn" onClick={openModal}>
        <img src="/filter-btn.png" alt="Filter" />
      </button>
      {isModalOpen && <FilterModal onClose={closeModal} />}
    </div>
  );
};

export default CardHeader;
