import React from "react";
import GroupRec from "./GroupRec";

function GroupRecsList({
  recs,
  setRecs,
  setVotedRecs,
  showSwipes,
  enableSwipe,
}) {
  return (
    <ul className="rec-list">
      {recs.map((rec, index) => (
        <GroupRec
          key={rec.rec_id}
          rec={rec}
          recs={recs}
          setRecs={setRecs}
          setVotedRecs={setVotedRecs}
          index={index}
          showSwipes={showSwipes}
          enableSwipe={enableSwipe}
        />
      ))}
    </ul>
  );
}

export default GroupRecsList;
