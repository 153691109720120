import React, { useRef, useEffect, useState } from "react";
import "./AutoComplete.css";

const AutoComplete = (props) => {
  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        initAutoComplete();
      } else {
        setTimeout(loadGoogleMapsScript, 100);
      }
    };

    loadGoogleMapsScript();
  }, []);

  const initAutoComplete = () => {
    const options = {
      componentRestrictions: { country: "us" },
      fields: [
        "address_components",
        "geometry",
        "name",
        "formatted_address",
        "price_level",
        "types",
        "url",
        "photos",
        "rating",
        "reviews",
        "opening_hours",
        "formatted_phone_number",
        "website",
        "user_ratings_total",
        "place_id",
      ],
      types: ["establishment"],
    };

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      props.setName(place.name);
      props.setAddress(place.formatted_address);

      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        props.setLatitude(lat);
        props.setLongitude(lng);
      }

      const resultData = {
        name: place.name,
        address: place.formatted_address,
        rating: place.rating,
        reviews: place.reviews,
        price_level: place.price_level,
        types: place.types,
        opening_hours: place.opening_hours,
        phone_number: place.formatted_phone_number,
        website: place.website,
        user_ratings_total: place.user_ratings_total,
        place_id: place.place_id,
      };

      console.log(resultData);
      console.log("AutoComplete Result Data:", resultData);

      setResults([resultData]);
      props.setResults([resultData]);
    });
  };

  return (
    <div className="auto-complete-container">
      <h2>add a rec!</h2>
      <input
        type="text"
        placeholder="search for a place"
        ref={inputRef}
        className="auto-complete-input"
      />
    </div>
  );
};

export default AutoComplete;
