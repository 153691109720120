// AreaFilter.js
import React from "react";
import { AREAS } from "./constants";

function AreaFilter({ currArea, setArea, isFilterModal }) {
  return (
    <aside>
      <ul className={isFilterModal ? "filter-modal-area-list" : "area-list"}>
        {AREAS.map((area) => (
          <li key={area.name}>
            <button
              className={`area ${currArea === area.name ? "active" : ""}`}
              onClick={() => setArea(area.name)}
              style={{ backgroundColor: area.color }}
            >
              {area.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default AreaFilter;
