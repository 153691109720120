import React from "react";
import { Link } from "react-router-dom";

function Header({ setShowForm, setShowLogin, userEmail }) {
  return (
    <header className={userEmail ? "header-centered" : ""}>
      <Link to="/">
        <div className="logo">
          <h1 className="sugar-logo">sugar</h1>
          <img
            src="/fingers-logo-red.png"
            alt="sugar-icon"
            className="sugar-icon"
            height="68"
            width="68"
          />
        </div>
      </Link>
      {!userEmail && (
        <button
          className="share-button"
          onClick={() => setShowLogin((prev) => !prev)}
        >
          login
        </button>
      )}
    </header>
  );
}

export default Header;
