export function adjustColor(color, amount) {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
}

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const handleSortByDistance = (recs) => {
  return new Promise((resolve, reject) => {
    const getUserLocation = () => {
      return new Promise((resolve, reject) => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          });
        } else {
          reject(new Error("Geolocation is not supported by your browser."));
        }
      });
    };

    const sortRecsByDistance = (recs, userLat, userLon) => {
      return recs
        .map((rec) => {
          const distance = calculateDistance(
            userLat,
            userLon,
            rec.latitude,
            rec.longitude
          );
          return { ...rec, distance };
        })
        .sort((a, b) => a.distance - b.distance);
    };

    getUserLocation()
      .then((position) => {
        const { latitude, longitude } = position.coords;
        const sortedByDistance = sortRecsByDistance(recs, latitude, longitude);
        resolve(sortedByDistance);
      })
      .catch((error) => {
        console.error("Error getting location:", error);
        alert(
          "Could not get your location. Please ensure location services are enabled."
        );
        reject(error);
      });
  });
};

export const handleSortByUpvotes = (recs, setRecs) => {
  const sortedByUpvotes = [...recs].sort((a, b) => b.upvotes - a.upvotes);
  setRecs(sortedByUpvotes);
};
