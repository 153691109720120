// FilterModal.js
import React, { useContext, useState, useEffect } from "react";
import "./FilterModal.css";
import AreaFilter from "./AreaFilter";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { AppContext } from "./AppContext";
import TagFilter from "./TagFilter";
import CategoryButtons from "./CategoryButtons";
import SortFilterButtons from "./SortFilterButtons";
import PriceFilter from "./PriceFilter";

const FilterModal = ({ onClose }) => {
  const { filters, setFilters, currCategory, setCurrCategory } = useContext(
    AppContext
  );
  const [localFilters, setLocalFilters] = useState({ ...filters });
  const [localCategory, setLocalCategory] = useState(currCategory);
  const [activeTab, setActiveTab] = useState("tags");

  useEffect(() => {
    setLocalFilters({ ...filters });
    setLocalCategory(currCategory);
  }, [filters, currCategory]);

  const handleCategoryChange = (category) => {
    setLocalCategory(category);
  };

  const handleTagClick = (tagName) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      selectedTags: prevFilters.selectedTags.includes(tagName)
        ? prevFilters.selectedTags.filter((tag) => tag !== tagName)
        : [...prevFilters.selectedTags, tagName],
    }));
  };

  const handleClearSelected = () => {
    setLocalFilters({
      selectedTags: [],
      selectedArea: "All",
      priceRange: [1, 4],
    });
    setLocalCategory("All");
  };

  const handleApplyFilters = () => {
    setFilters(localFilters);
    setCurrCategory(localCategory);
    onClose();
  };

  const handlePriceRangeChange = (value) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      priceRange: value,
    }));
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "tags":
        return (
          <>
            <h3 className="filter-section-title">what's the vibe?</h3>
            <TagFilter
              handleTagClick={handleTagClick}
              localTagsSelected={localFilters.selectedTags}
              handleClearSelected={handleClearSelected}
              currCategory={localCategory}
            />
          </>
        );
      case "price":
        return (
          <>
            <h3 className="filter-section-title">name your price.</h3>
            <PriceFilter
              priceRange={localFilters.priceRange}
              setPriceRange={handlePriceRangeChange}
            />
          </>
        );
      case "area":
        return (
          <>
            <h3 className="filter-section-title">where?</h3>
            <AreaFilter
              currArea={localFilters.selectedArea}
              setArea={(area) =>
                setLocalFilters({ ...localFilters, selectedArea: area })
              }
              isFilterModal={true}
            />
          </>
        );
      case "category":
        return (
          <>
            <h3 className="filter-section-title">looking for a..</h3>
            <CategoryButtons
              currCategory={localCategory}
              setCurrCategory={handleCategoryChange}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="filter-modal-backdrop" onClick={onClose}>
      <div
        className="filter-modal-content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="filter-modal-header">
          <h2>Filters</h2>
          <button className="filter-modal-close-btn" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="filter-tabs">
          {["tags", "price", "area", "category"].map((tab) => (
            <button
              key={tab}
              className={`filter-tab ${activeTab === tab ? "active" : ""}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
        <div className="filter-content">{renderTabContent()}</div>
        <div className="filter-footer">
          <button className="filter-clear-btn" onClick={handleClearSelected}>
            Clear all
          </button>
          <button className="filter-apply-btn" onClick={handleApplyFilters}>
            Show results
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
