import "./style.css";
import { useEffect, useState } from "react";
import { supabase } from "./supabase";
import NewRecForm from "./NewRecForm";
import CategoryFilter from "./CategoryFilter";
import AreaFilter from "./AreaFilter";
import RecsList from "./RecsList";
import Header from "./Header";
import Auth from "./Auth"; // Import the modified Auth component
import { AuthProvider, useAuth } from "./AuthContext";
import { CATEGORIES, AREAS } from "./constants";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import CategoryPage from "./CategoryPage";
import Loader from "./Loader";
import Profile from "./Profile";
import FilterPage from "./FilterPage";
import ListsPage from "./ListsPage"; // Import the new ResultsPage component
import { AppProvider } from "./AppContext";
import Home from "./Home";
import DecideWithFriendsPage from "./DecideWithFriendsPage";
import ShareableRec from "./ShareableRec";
import BottomNavBar from "./BottomNavBar"; // Import the new BottomNavBar component
import mixpanel from "mixpanel-browser";

mixpanel.init("052ccacf464192741eac9323994b86a5", { debug: true });

function AppContent() {
  const [showForm, setShowForm] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const { user } = useAuth();
  const [recs, setRecs] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const handleDecideWithFriends = async () => {
    const uniqueLink = Math.random()
      .toString(36)
      .substr(2, 6);

    // Initialize the group in Supabase
    const { data, error } = await supabase
      .from("group_recs")
      .insert([{ link: uniqueLink }]);

    if (error) {
      alert("Error initializing group: " + error.message);
      return;
    }

    navigate(`/decide/${uniqueLink}`);
  };

  return (
    <div className="app-container">
      <nav>
        {(location.pathname === "/" ||
          location.pathname.includes("decide")) && (
          <Header
            setShowForm={setShowForm}
            setShowLogin={setShowLogin}
            userEmail={user ? user.email : null}
          />
        )}
        {showLogin && !user && <Auth onClose={() => setShowLogin(false)} />}
        {showForm && user && (
          <NewRecForm setRecs={setRecs} setShowForm={setShowForm} />
        )}
      </nav>
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category" element={<CategoryPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/filter" element={<FilterPage />} />
          <Route path="/lists" element={<ListsPage />} />
          <Route path="/decide/:link" element={<DecideWithFriendsPage />} />
          <Route path="/recommendation/:id" element={<ShareableRec />} />
        </Routes>
      </div>
      <BottomNavBar
        setShowForm={setShowForm}
        user={user}
        handleDecideWithFriends={handleDecideWithFriends}
      />
    </div>
  );
}

function App() {
  return (
    <AppProvider>
      <Router>
        <AppContent />
      </Router>
    </AppProvider>
  );
}

export default App;
