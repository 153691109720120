// CategoryButtons.js
import React, { useContext } from "react";
import { AppContext } from "./AppContext";
import { CATEGORIES } from "./constants";
import "./FilterPage.css";

const CategoryButtons = ({
  currCategory: propCurrCategory,
  setCurrCategory: propSetCurrCategory,
}) => {
  const { currCategory, setCurrCategory } = useContext(AppContext);

  // Determine the category and setter to use
  const currentCategory =
    propCurrCategory !== undefined ? propCurrCategory : currCategory;
  const setCurrentCategory =
    propSetCurrCategory !== undefined ? propSetCurrCategory : setCurrCategory;

  const handleCategoryClick = (category) => {
    console.log("Category button clicked:", category);
    setCurrentCategory(category);
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const categoryRows = chunkArray(CATEGORIES, 3);

  return (
    <div>
      {categoryRows.map((row, rowIndex) => (
        <div className="filter-buttons" key={rowIndex}>
          {row.map((category) => (
            <button
              key={category.name}
              className={`filter-button ${
                currentCategory === category.name ? "active" : ""
              }`}
              onClick={() => handleCategoryClick(category.name)}
              style={{
                backgroundColor:
                  currentCategory === category.name
                    ? category.color
                    : "#f2f2f2",
                color: currentCategory === category.name ? "white" : "black",
              }}
            >
              {category.name}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CategoryButtons;
