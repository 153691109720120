// TagFilter.js
import React, { useState, useEffect, useContext } from "react";
import { supabase } from "./supabase";
import Tag from "./Tag"; // Adjust the import path as necessary
import "./Tag.css";

function TagFilter({
  handleTagClick,
  localTagsSelected,
  handleClearSelected,
  currCategory,
}) {
  const [tags, setTags] = useState([]);
  const [showAllTags, setShowAllTags] = useState(false);

  useEffect(() => {
    async function fetchTags() {
      const { data, error } = await supabase
        .from("recs_votes") // Replace with your table name
        .select("categories, category"); // Fetch categories and the main category

      if (error) {
        console.error("Error fetching unique tags:", error);
        return [];
      }

      if (!data || data.length === 0) {
        console.log("No tags found.");
        return [];
      }

      // Filter data based on the current category if it is not "All"
      const filteredData =
        currCategory === "All"
          ? data
          : data.filter((rec) => rec.category === currCategory);

      const allTags = filteredData.map((rec) => rec.categories).filter(Boolean);

      const splitTags = allTags.flatMap((tag) => tag.split(", "));

      const tagCount = splitTags.reduce((acc, tag) => {
        acc[tag] = (acc[tag] || 0) + 1;
        return acc;
      }, {});

      const uniqueTags = Object.entries(tagCount).map(([tag, count]) => ({
        tag,
        count,
      }));

      // Sort tags by count in descending order
      uniqueTags.sort((a, b) => b.count - a.count);

      setTags(uniqueTags);
    }

    fetchTags();
  }, [currCategory]); // Add currCategory as a dependency

  const toggleShowAllTags = () => {
    setShowAllTags((prevShowAllTags) => !prevShowAllTags);
  };

  const visibleTags = showAllTags ? tags : tags.slice(0, 12); // Show only the first 12 tags initially

  return (
    <div>
      <div className="tag-filter-container">
        <div className="tag-filter">
          {visibleTags.map(({ tag, count }, index) => (
            <Tag
              key={index}
              label={tag}
              count={count}
              handleTagClick={handleTagClick}
              isSelected={localTagsSelected.includes(tag)}
            />
          ))}
        </div>
      </div>
      <div className="tag-buttons">
        <div className="tag button-style" onClick={toggleShowAllTags}>
          <img
            src={showAllTags ? "./remove-btn-red.png" : "./add-btn-red.png"}
            alt="Toggle"
            className="add-btn"
          />
          <div className="tag-text">
            <span className="tag-name">
              {showAllTags ? "see less" : "see more tags"}
            </span>
          </div>
        </div>
        <div className="tag button-style" onClick={handleClearSelected}>
          <img src="./cancel-btn-red.png" alt="Clear" className="add-btn" />
          <div className="tag-text">
            <span className="tag-name">clear selected</span>
            <span className="tag-separator"> | </span>
            <span className="tag-count">{localTagsSelected.length}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagFilter;
