import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "./RecModal.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CATEGORIES } from "./constants";
import {
  FaStar,
  FaDollarSign,
  FaPhone,
  FaGlobe,
  FaClock,
  FaCircle,
} from "react-icons/fa";
import { supabase } from "./supabase";
import "./ShareableRec.css";

function ShareableRec() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [rec, setRec] = useState(null);

  useEffect(() => {
    async function fetchRec() {
      const { data, error } = await supabase
        .from("recs_votes")
        .select("*")
        .eq("id", id)
        .single();

      if (error) {
        console.error("Error fetching recommendation:", error);
      } else {
        // Find the emoji for the category
        const category = CATEGORIES.find((cat) => cat.name === data.category);
        const emoji = category ? category.emoji : "📋";
        setRec({ ...data, emoji });
      }
    }

    fetchRec();
  }, [id]);

  return (
    <div className="shareable-rec-container">
      <div className="rec-modal-header">
        <div className="back-btn-container" onClick={() => navigate("/")}>
          <img src="/back-btn.png" alt="Back" className="back-btn-img" />
        </div>
        {rec ? (
          <div className="header-content">
            <span className="rec-modal-emoji">{rec.emoji}</span>
            <h2 className="rec-modal-name">{rec.name}</h2>
          </div>
        ) : (
          <h2>Loading...</h2>
        )}
      </div>
      {!rec ? (
        <div className="shareable-rec-loading">Loading details...</div>
      ) : (
        <RecDetails rec={rec} />
      )}
    </div>
  );
}

const RecDetails = ({ rec }) => {
  if (!rec) return null;

  const {
    name,
    address,
    photo_urls,
    rating,
    reviews,
    opening_hours,
    phone_number,
    website,
    price_level,
    types,
    user_ratings_total,
  } = rec;

  const parsedReviews = reviews ? JSON.parse(reviews) : [];
  const parsedOpeningHours = opening_hours
    ? JSON.parse(opening_hours).weekday_text
    : [];
  const parsedTypes = types ? JSON.parse(types) : [];
  const periods = opening_hours ? JSON.parse(opening_hours).periods : [];

  const isOpenNow = (periods) => {
    const now = new Date();
    const day = now.getDay(); // Sunday - Saturday: 0 - 6
    const currentTime = now.getHours() * 100 + now.getMinutes(); // Convert current time to HHMM format

    return periods.some((period) => {
      if (period.open.day === day && period.close.day === day) {
        return (
          currentTime >= parseInt(period.open.time) &&
          currentTime < parseInt(period.close.time)
        );
      } else if (period.open.day === day && period.close.day !== day) {
        return currentTime >= parseInt(period.open.time);
      } else if (period.open.day !== day && period.close.day === day) {
        return currentTime < parseInt(period.close.time);
      }
      return false;
    });
  };

  const openStatus = isOpenNow(periods) ? "open now" : "closed";

  const photoSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    swipeToSlide: true, // Allow continuous swiping
    arrows: false, // Hide navigation arrows
  };

  const reviewSliderSettings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    swipeToSlide: false, // Allow continuous swiping
    arrows: true, // Show navigation arrows
    prevArrow: <div className="slick-prev">{"<"}</div>,
    nextArrow: <div className="slick-next">{">"}</div>,
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="shareable-rec">
      <div
        className="rec-modal-content"
        onClick={stopPropagation}
        onTouchMove={stopPropagation}
      >
        {Array.isArray(parsedTypes) && parsedTypes.length > 0 && (
          <div className="rec-modal-types">
            {parsedTypes.map((type, idx) => (
              <span key={idx} className="rec-modal-type">
                {type.replace("_", " ")}
              </span>
            ))}
          </div>
        )}

        <p className="rec-modal-address">{address}</p>

        <div className="rec-modal-info">
          {photo_urls && (
            <Slider {...photoSliderSettings} className="photo-slider">
              {photo_urls.split(", ").map((url, idx) => (
                <div key={idx} className="modal-photo-container">
                  <img
                    src={url}
                    alt={`Photo ${idx + 1}`}
                    className="modal-photo"
                  />
                </div>
              ))}
            </Slider>
          )}

          <div className="rec-modal-info-row">
            <p
              className={`rec-modal-status rec-modal-info-item ${openStatus
                .toLowerCase()
                .replace(" ", "-")}`}
            >
              <FaCircle
                className={`status-icon ${
                  openStatus === "open now" ? "open" : "closed"
                }`}
              />
              {openStatus}
            </p>

            {rating && (
              <p className="rec-modal-rating rec-modal-info-item">
                <FaStar className="icon" /> {rating} ({user_ratings_total}{" "}
                reviews)
              </p>
            )}

            {price_level !== undefined && (
              <p className="rec-modal-price rec-modal-info-item">
                <FaDollarSign className="icon" /> {"$".repeat(price_level)}
              </p>
            )}
          </div>

          {parsedReviews.length > 0 && (
            <div className="rec-modal-reviews">
              <h3>reviews</h3>
              <Slider {...reviewSliderSettings} className="review-slider">
                {parsedReviews.map((review, idx) => (
                  <div key={idx} className="review">
                    <p>"{review.text}"</p>
                    <p className="review-author">
                      <strong>{review.author_name}</strong>
                      <span>{review.relative_time_description}</span>
                    </p>
                  </div>
                ))}
              </Slider>
            </div>
          )}

          {parsedOpeningHours.length > 0 && (
            <div className="rec-modal-hours">
              <h3>
                <FaClock className="icon" />
                opening hours
              </h3>
              <ul>
                {parsedOpeningHours.map((time, idx) => (
                  <li key={idx}>{time}</li>
                ))}
              </ul>
            </div>
          )}
        </div>

        <div className="rec-modal-contact">
          {phone_number && (
            <p className="rec-modal-phone">
              <FaPhone className="icon" />
              <a
                href={`tel:${phone_number.replace(/\D/g, "")}`}
                className="phone-link"
              >
                call {phone_number}
              </a>
            </p>
          )}
          {website && (
            <p className="rec-modal-website">
              <FaGlobe className="icon" />
              <a href={website} target="_blank" rel="noopener noreferrer">
                visit website
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShareableRec;
